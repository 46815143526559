<template>
  <div class="page-1">
    <div class="v1">扫码加入我们的用户反馈群！</div>
    <div class="v3">
      <img src="@/assets/img/cooperate/1.png">
    </div>
    <div class="v2">韵帆数字（厦门）科技有限公司成立于2022年，是一家从事于云手机研发及提供云服务的高新技术公司。公司核心团队均来自于英特尔及发及华为的虚拟化及云技术顶尖专家，拥有多项技术专利，团队产品用户全球累计近3亿，拥有丰富的技术、产品及服务成功经验。公司始终以用户需求为中心，自研产品为基础，采用先进的无线互联网、数据处理、虚拟云等技术，为云手机用户提供一个多平台互通的能够提供下载、应用及管理的云手机平台软件。</div>
    <div class="v2">公司尊祟“以人为本、以技术为先、以市场为导向”的经营理念珍视每一个用户的反馈,不断选代优化产品,为用户提供更高品质、由低价格的高性价比产品。</div>
    <!-- <div class="v4">扫码联系 or <a href="">给我们留言</a></div> -->
  </div>
</template>

<script>
export default {
  name : 'Page1'
}
</script>

<style scoped>
.page-1{
  width: 100%;
  height: 1000px;
  background: linear-gradient(90deg,#a75fe4,#7327ec);

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

}

.v1{
  font-size: 4rem;
  color: #FFF;
  text-align: center;
}

.v2{
  font-size: 1.5rem;
  text-align: center;
  color: #FFF;
  width: 860px;
  line-height: 2.5rem;
}
.v3 {
  margin: 60px 0;
}

.v4{
  font-size: 1.3rem;
  text-align: center;
  color: #FFF;
}

.v4 a{
  font-size: 1.3rem;
  text-align: center;
  color: #FFF;
}
</style>