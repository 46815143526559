import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rowData : [],
    form: {//网络获取的数据
      devName: [],
      proType: [],
      firVer: [],
    },
  },
  mutations : {
    /**
     * @description 推送最新查询数据至Vuex
     * @param {*} state 
     * @param {*} data 
     */
    pushIpswlist(state,data){
      state.rowData = data;
    },

    /**
     * @description 推送最新请求数据至Vuex
     * @param {*} state 
     * @param {Array} devName 设备名称 「允许为null」
     * @param {Array} proType 产品类型 「允许为null」
     * @param {Array} firVer  固件版本 「允许为null」
     */
    pushData(state,[devName,proType,firVer]){
      if(devName != null){
        state.form.devName = devName;
      }
      if(proType != null){
        state.form.proType = proType;
      }
      if(firVer != null){
        state.form.firVer = firVer;
      }
    }
  }
})
