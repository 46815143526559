import { render, staticRenderFns } from "./VIPAgreement.vue?vue&type=template&id=495a0a5f"
import script from "./VIPAgreement.vue?vue&type=script&lang=js"
export * from "./VIPAgreement.vue?vue&type=script&lang=js"
import style0 from "./VIPAgreement.vue?vue&type=style&index=0&id=495a0a5f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports