<template>
   <div class="page-a">
     <div class="left">
       <img src="@/assets/img/i_40.png" style="height: 68vh; width: auto;">
     </div>

     <div class="right">
       <div class="v1">飞天云 内置各类游戏工具的云手机</div>

       <div class="v2">
         <span>飞天云手机，安卓10系统，月卡28元，免费试用3小时，南北方网络任意选，拒绝延迟。</span><br>
       </div>

       <div class="v3">
         <div class="v3-left" @click="download()">
           <div class="v3-left-connent">
            <img src="@/assets/img/i_31.png">
            <span>安卓端下载</span>
           </div>
         </div>
         <div class="v3-left" @click="ios()">
           <div class="v3-left-connent">
            <img src="@/assets/img/i_ios.png">
            <span>IOS 网页端</span>
           </div>
         </div>
         <div class="v3-left" @click="pc()">
           <div class="v3-left-connent">
            <img src="@/assets/img/i_31.png">
            <span>电脑端 试用</span>
           </div>
         </div>
         <div class="v3-right ftyun-down-img">
           <img src="@/assets/img/ftyundown.png">
         </div>
         <div class="v3-right">
           <a href="#" @click="backup()">网盘下载</a>
         </div>
       </div>

        <div class="v4">
          <span class="v4-1">版本号：{{ version }}&nbsp;|&nbsp;</span>
          <span class="v4-2">{{ dateTime }}&nbsp;&nbsp;</span>
        </div>
        <br>
        <div class="v4">
          <span class="v4-1"><a href="#" class="agreement-a" @click="toUser">《用户协议》</a>&nbsp;|&nbsp;</span>
          <span class="v4-2"><a href="#" class="agreement-a" @click="toPrivacy">《隐私政策》</a>&nbsp;&nbsp;</span>
        </div>
     </div>
   </div>
</template>

<script>
  import { request } from '@/network/request-download';
  export default {
    name : "PageA",
    data() {
      return {
        version: '1.2.6',
        dateTime: '2024-02-02'
      }
    },
    created() {
      this.getVersion()
    },
    methods: {
      download(){
        request({
          url: `api/version/android/newApk/飞天云`,
          method: 'get',
        }).then(res => {
          if(res.code == 200) {
            window.open(res.data);
          }
        });
      },
      ios() {
        window.open('https://www.ftyunos.com:1000', '_blank')
      },
      pc(){
        request({
          url: `api/version/android/newApk/飞天云PC`,
          method: 'get',
        }).then(res => {
          if(res.code == 200) {
            window.open(res.data);
          }
        });
      },
      backup(){
        window.open('https://wwp.lanzoul.com/s/ftyunos')
      },
      getVersion() {
        request({
          url: `api/version/android/versionDate/飞天云`,
          method: 'get',
        }).then(res => {
          if(res.code == 200) {
            this.version = res.data.version
            this.dateTime = res.data.dateTime
          }
        });
      },
      toUser(){
        this.$router.push('/UserAgreement');
      },
      toPrivacy(){
        this.$router.push('/PrivacyAgreement');
      }
    },
  }
</script>

<style scoped>

@media screen and (max-width: 1920px) {
  .v1 {
    font-size: 3rem;
  }
  .v2 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 800px) {
  .v1 {
    font-size: 1.2rem;
  }
  .v2 {
    font-size: 1rem;
  }
}

.ftyun-down-img img{
  width: 6rem;
}

.page-a{
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 80%;
}

.left{
  flex : 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left img{
  width: 500px;
}

.right{
  flex : 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.v1{
  color: #FFF;
  letter-spacing: 1px;
  text-align: center;
}

.v2{
  color: #FFF;
  line-height: 2.2rem;
  margin: 20px 0;
  width: 90%;
}

.v3{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  
}

.v3-left{
  width: 220px;
  height: 40px;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;

  cursor:pointer;
  user-select: none;
  
}

.v3-left:hover{
  box-shadow: #FFF 0px 0px 8px 1px;
}
.v3-left-connent{
  display: flex;
  justify-content: center;
  align-items: center;
}

.v3-left-connent img{
  width: 30px;
  filter: drop-shadow(30px 0 0 #bc60a4);
  margin-left: -30px;
  margin-right: 40px;
  
}

.v3-left-connent span {
  color: #bc60a4;
  font-size: 1.4rem;
  letter-spacing: 1px;
  text-align: center;
   
}

.v3-right{
  margin-left: 30px;
  margin-top: 30px;
}

.v3-right a{
  color: #FFF;
  letter-spacing: 1px;
  text-decoration: none;
}

.v4{
  display: flex;
  color: #FFF;
  font-size: 1rem;
  letter-spacing: 1px;
}



.v4-3 img{
  width: 18px;
}
.v4-3 img:nth-child(2){
 margin: 0 15px;
}
.agreement-a {
  color: #FFF;
}
</style>