<template>
  <div class="agreement">
    <h2>飞天云手机VIP会员服务协议</h2>
    
<p>为使用飞天云手机VIP会员服务（以下简称为：本服务），请您仔细阅读、理解并遵守 《飞天云手机VIP会员服务协议》（以下简称为：本协议）。</p>
<p>本协议由您与韵帆数字（厦门）科技有限公司（以下简称为：韵帆）共同缔结，具有合同效力。韵帆以黑体加粗或其他合理方式提示您注意并请您重点阅读本协议中可能与您的权益有重大利害关系的条款，包括但不限于免除韵帆责任的条款（以下简称：免责条款）、对用户权利进行限制的条款（以下简称：限制条款）、约定争议解决方式和司法管辖的条款等。如果您认为该等条款可能导致您在特定情况下部分或全部利益或权利受损，请您在确认同意本协议之前或在使用本服务之前务必再次阅读前述条款，并在您自愿接受该等条款的前提下使用本服务。</p>
<p>如果您不同意本协议下的任意或全部条款内容，尤其是前述可能与您的权益存在重大厉害关系的条款，请不要以接受本服务的形式（包括但不限于点击同意/接受/下一步、进入购买程序、进行或参与任何与本服务相关的交易或活动、采用任何其他形式的确认操作，以下统称：接受服务的形式）进行下一步操作或使用本服务。当您采用接受服务的形式进行下一步操作或使用本服务，即表明您已阅读、理解并同意签署本协议，表示您与飞天云手机已达成协议关系，并自愿接受并遵守本协议的全部约定。</p>
<p>如果您未满18周岁，请在法定监护人的陪同下阅读并确认本协议条款。</p>
<p>一、【协议的定义及说明】</p>
<p>1.1【飞天云手机VIP会员及服务系统】</p>
<p>您如果需要使用和享受飞天云手机VIP会员服务，则您需要在飞天云手机会员服务购买界面以购买的方式获取，购买成功后您在会员时长有效期内享受相应的会员权益。如果您在第三方看到飞天云手机VIP会员服务购买信息的，请先向飞天云手机客服核实或通过指定邮箱核实第三方服务提供方信息的真假，以防上当受骗。</p>
<p>飞天云手机VIP会员服务可在IOS及安卓系统使用，iPhone云手机与安卓云手机服务是不同内容及类型的服务，iphone系统的飞天云手机云手机服务仅支持IOS系统的部分游戏。若您是iphone系统的用户，请您在购买服务前仔细查看提供服务支持的游戏列表，了解是否有您需要的游戏，再决定是否开通iphone系统的飞天云手机VIP会员的服务。您理解并同意，鉴于网络服务的特殊性，韵帆数字有权根据市场情况进行会员权益及特色会员服务的全部或部分变更、调整、取消、增加。</p>
<p>1.2【本协议】</p>
<p>本协议视为 《飞天云手机云手机平台用户使用协议》、《隐私政策》 的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。</p>
<p>本协议内容同时包括韵帆数字可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，同样对双方生效。</p>
<p>1.3【协议修改】</p>
<p>飞天云手机会员服务提供方不时发布并修订的关于飞天云手机会员的用户守则、活动规则、使用规则、公告、提示、通知及在线FAQ等内容。</p>
<p>韵帆有权对本协议进行修改（修改包含变更、增加、减少相应的条款内容），一旦本协议发生修改，韵帆将通过系统提示和/或信息推送和/或后台公告等形式发布，请您务必仔细阅读。当您采用接受服务的形式进行下一步操作或继续使用本服务，即表明您已经接受本协议的全部修改。如您对本协议的修改有任何问题，可以邮件咨询（联系邮箱：tttools1797@gmail.com  ）。更新后的本协议自更新版发布之日起生效。</p>
<p>1.4【使用原则】</p>
<p>您在使用飞天云手机VIP会员服务过程中，应遵守宪法法律，包括但不限于《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《信息网络传播权保护条例》、《网络安全法》等有关计算机及互联网规定的法律法规；应遵守公共秩序，尊重社会公德，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，不得煽动颠覆国家政权、推翻社会主义制度，不得煽动分裂国家、破坏国家统一，不得宣扬恐怖主义、极端主义，不得宣扬民族仇恨民族歧视，不得传播暴力、淫秽色情信息，不得编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等活动。在任何情况下，韵帆一旦合理地认为用户存在上述行为的，可以在任何时候，不经事先通知，终止向该用户提供服务。</p>
<p>二、【飞天云手机VIP会员账号管理及使用】</p>
<p>2.1【账号管理】</p>
<p>您应正确使用及妥善保管、维护您的飞天云手机VIP账号及密码，如发生任何泄露、遗失、被盗等行为，而该等行为并非韵帆法定过错导致，所有损失将由您自行承担。若您发现他人冒用或盗用其账号及密码或其他未经合法授权的使用或任何其他不安全的行为，您应立即以有效方式通知韵帆，要求飞天云手机平台暂停相关服务。同时，您理解韵帆对您的请求采取行动需要合理期限，在此之前，飞天云手机平台对已执行的指令及所导致您的损失不承担任何责任；</p>
<p>2.2【使用规则】</p>
<p>2.2.1【合法获取及使用】</p>
<p>（1）任何以盗窃、利用系统漏洞、通过任何非飞天云手机官方或授权途径获得的飞天云手机VIP会员服务（包括但不限于购买、租用、借用、受让等方式获得）、恶意利用飞天云手机的授权功能或破坏飞天云手机VIP会员活动获得的会员服务，均为不合法行为，不获本协议项下的相关保护，飞天云手机有权取消及不予提供会员服务，所有产生的损失及责任由侵权行为人自行承担。</p>
<p>（2）使用飞天云手机VIP会员服务或参加相关优惠活动时，亦应通过飞天云手机发布授权的VIP会员服务购买界面的方式进行，用户通过其他任何渠道、任何途径、任何方式非法获取的飞天云手机VIP会员服务，均为不合法行为，不获本协议项下的相关保护，且一经发生飞天云手机有权立即就相关服务做出删除、取消、封停等处理，或采取其他如中止或终止对您提供服务等账号管理措施，所有因此产生的损失及责任由侵权行为人自行承担。</p>
<p>（3）您参加会员活动时，应按照活动规则，以及合法及不损害活动举办方利益的方式进行，利用系统漏洞、规则设置缺陷、系统设置错误、滥用会员身份等方式参加活动并获取相关优惠的，均为不合法行为，不获得本协议项下的相关保护，且飞天云手机有权采取相关措施进行处理，所有因此产生的损失及责任由侵权行为人自行承担。</p>
<p>（4）你在使用本服务时应当遵守国家法律法规、政策及飞天云手机平台发布的使用规则。</p>
<p>  2.2.2【专有及非商业目的使用】</p>
<p>（1）飞天云手机VIP会员服务，是您在遵守本协议及相关法律法规的前提下，飞天云手机给予您一项个人的、不可转让的、非商业用途的、可撤销的、有期限及排他性的许可，您仅可个人非商业目的的使用。</p>
<p>（2）您理解并同意，除本协议约定的授权功能、转移功能使用范围，您不得将享有的飞天云手机VIP会员服务通过上述功能及其他任何方式提供给他人使用，包括但不限于不得利用上述功能授权、转移至第三方使用，不得通过转让、出租、借用、出售等方式提供给他人使用。否则，因此产生的任何法律后果及责任均由您自行承担，且飞天云手机平台有权中止或终止对您提供服务</p>
<p>（3）授权功能使用范围：本软件中的授权功能仅用于您在云手机使用过程中发生异常情况，必须通过授权功能授权第三方帮您进行协同工作解决异常问题时使用，除此情况外，不得利用授权功能进行任何商业目的的使用。同时请您注意：由于使用授权功能在授权第三方操作过程中，存在您的隐私信息被第三方泄露的风险，故请您在必要情况下谨慎使用该功能。如果因为授权功能引发您与第三方之间的纠纷，由您与第三方自行解决，韵帆无需承担任何责任。使用授权功能前，请您仔细阅读并同意《飞天云手机授权功能服务协议》。</p>
<p>（4）转移功能使用范围：本软件中的转移功能仅用于您在需要更换云手机管理账号时使用，除此情况外，不得利用转移功能进行任何目的的使用。</p>
<p>（5）您理解并同意，韵帆有权了解您使用本软件服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息，如果韵帆有合理理由怀疑您提供的信息不真实、进行无授权线下交易、虚假交易，或您的行为违反飞天云手机平台的服务规则，飞天云手机云手机有权暂时或永久限制您账号下所使用服务的部分或全部功能。</p>
<p>（6）若您有以下行为之一的，飞天云手机将直接认定您的行为是以商业目的或非法利用授权功能、转移功能，飞天云手机有权对您的账号进行暂时或永久限制部分或全部功能，您可通过飞天云手机客服中心页面联系人工客服进行申诉，申诉时您需提交相应的证明材料证明您的行为并未涉及营利活动。</p>
<p>a. 以营利为目的，利用平台授权功能、转移功能，在第三方电商及社交平台发布云手机出租或转让等信息；</p>
<p>b. 月周期内将您飞天云手机账户下的部分或全部云手机通过授权功能授权给任意第三方用户使用的行为超过50次的。</p>
<p>c. 以其他非法或违规形式利用平台授权功能、转移功能的。</p>
<p> 2.2.3【使用准则特别提示】</p>
<p>（1）不得利用本服务进行任何侵犯飞天云手机及任何第三方的知识产权、财产权、名誉权等合法权益的行为；</p>
<p>（2）本服务是一项收费服务，以任何手段侵犯、破坏飞天云手机收取费用的行为，飞天云手机保有追究行为人责任及索取赔偿的权利；</p>
<p>（3）不得利用本服务进行任何危害或涉嫌危害未成年人的行为；</p>
<p>（4）不得利用本服务从事任何违反法律法规、政策或公序良俗、社会公德的行为；</p>
<p>（5）您充分了解并同意，您必须为自己登录账号下的一切行为负责，包括但不限于您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险。</p>
<p>（6）每个飞天云手机帐号之间的充值记录、视频数据无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该等帐号是否由同一使用人拥有，请您在登录及/或充值/参加活动时注意区分，避免造成损失。</p>
<p> 2.2.4【账号找回】</p>
<p>  您登录飞天云手机时使用的账号信息遗失、忘记密码，导致无法登录飞天云手机的，您如需找回的，需按照韵帆数字的帐号找回流程的要求提供相应的信息和证明，并确保提供的信息和证明文件的合法真实有效，若提供的材料不真实或不符合要求，无法通过韵帆数字的安全验证，可能会导致帐号找回失败，相应的风险和损失将由您自行承担。 您理解韵帆数字对您的请求采取行动及处理均需要合理时间，除韵帆数字存在法定过错外，韵帆数字对您的损失不承担任何责任。</p>
<p>三、【飞天云手机VIP会员服务特别约定】</p>
<p>    3.1【支付】</p>
<p>您通过支付界面指定的支付途径成功购买本服务后，除法定情形外，不可进行转让或要求退费，除非因飞天云手机产品技术原因，导致您购买时被重复扣款或因飞天云手机产品技术原因导致您无法享有购买的服务时，您可提出退款申诉，经过韵帆数字核实后可为您办理退款，但您需要提供相应信息并配合韵帆数字处理。。</p>
<p>本服务是韵帆数字提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。韵帆数字可能会根据本服务的整体规划，对本服务相关权益细则、收费标准、方式等进行修改和变更，前述修改、变更，韵帆数字将在相应服务页面进行更新展示。韵帆数字会根据实际状况，对不同阶段充值、续费的会员给予不同的优惠，具体优惠政策以韵帆数字在相关服务页面公示的信息为准。同时我们也鼓励您定期查看本协议内容，以更好地保障您的权益。 您在此理解并同意因参加活动不同或会员等级不同，飞天云手机会员将享受不同的增值服务或资费。</p>
<p>您在购买飞天云手机会员后可享受的全部权益以飞天云手机官方网站公布的会员权益为准，韵帆数字有权基于自身业务发展需要变更全部或部分会员权益。就前述权益调整飞天云手机会通过在相应服务页面更新展示、系统提示、信息推送和／或您在我方平台留下的联系方式通知和提醒您注意，您也可通过飞天云手机官方网站或移动端查询最新的会员权益内容。</p>
<p>您不得通过以下任何方式为自己或他人开通本服务：</p>
<p>（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；</p>
<p>（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等程序、软件方式为自己或他人开通本服务；</p>
<p>（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；</p>
<p>（4）通过非飞天云手机指定的方式为自己或他人开通本服务；</p>
<p>（5）通过侵犯飞天云手机或他人合法权益的方式为自己或他人开通本服务；</p>
<p>（6）通过其他违反相关法律、行政法规、国家政策等方式为自己或他人开通本服务</p>
<p>     3.2【服务期限】</p>
<p>      除本协议另有约定外，本服务的期限以您自行选择并成功支付相应服务费用的期限为准，自您选择并购买本服务后生效，直至服务到期或您终止本服务/注销会员资格时终止，您也可以向客服或相关服务页面查询。</p>
<p>     您理解、知悉并同意，本服务的服务期间，由于互联网服务的特殊性，为向您提供优质服务，飞天云手机须不定时进行服务器的维修、调整、升级等，且在黑客攻击、计算机病毒侵入、监管机构政策通知等不可抗力事件发生后，飞天云手机须解决故障并处理相关侵权事宜，因此您接受，本服务的服务期限包含飞天云手机解决故障、服务器维修、调整、升级、因第三方侵权处理等所需的合理时间，韵帆数字对上述情况所需用的时间不另行补偿。</p>
<p> 3.3【关于飞天云手机VIP会员权益的特别提示】</p>
<p> 您理解并同意，由于您使用的设备终端的产品技术不同，飞天云手机VIP会员权益和福利可能在不同终端有所差异，具体以飞天云手机的页面说明及该终端可以支持的服务为准，由此可能给您带来的不便，您表示理解，您不予追究或者豁免韵帆数字的相关责任。韵帆有权根据法律规定及政策变更、运营需求等进行飞天云手机VIP会员权益及特色会员服务内容的部分或全部变更、调整、取消、增加，就前述权益的调整，韵帆将在VIP会员页面、相应服务页面或以其他合理方式进行发布，并在发布后生效。发布后，当您采用接受服务的形式进行下一步操作或继续使用本服务，即表明您已经仔细阅读并接受飞天云手机更新后的VIP会员服务。如您对本协议的修改有任何问题，可以咨询客服。</p>
<p>基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金，亦不得用于商业领域，如买卖、置换、抵押等。为了保证您的用户权益，请您通过官方渠道购买飞天云手机会员产品，一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获保护，韵帆数字有权中止或终止对您的服务；由此带来的损失，飞天云手机不承担相关责任。</p>
<p>  3.4【第三方软件及服务】</p>
<p>本软件可能使用或包含了由第三方提供的软件或服务（以下称“此类服务”），此类服务是响应您需求，为了向您提供更多便利而设置，韵帆数字不提供内容制作。您使用此类服务时，应另行与该第三方达成服务协议，支付相应费用并承担可能的风险。您如果继续使用本软件或服务，即表明您已经仔细阅读、理解并接受以下内容：</p>
<p>（1）飞天云手机无权在本协议中授予您使用第三方软件或服务的任何权利，也无权对此类服务提供任何形式的保证以及相应的客户服务支持，如果您需要咨询相关信息，可直接与该第三方联系。如果您因使用此类服务引发任何纠纷或造成任务损失，可直接与该第三方协商解决，飞天云手机平台不承担任何责任和赔偿。</p>
<p>（2）您现使用的本软件服务及本软件当前包含的服务，飞天云手机在此不保证您可永久使用本服务或本软件包含的服务永久不变，不保证将来不会使用除现有以外的其他同类或不同类型的软件或服务，一旦本软件中更改/增加服务内容或升级为包含前述服务内容的软件，其同样适用本协议规定。</p>
<p>（3）如果第三方确认您违反了您与其之间关于使用此类服务的协议约定，并停止对您提供此类服务，同时要求飞天云手机协助处理的，您理解并认可飞天云手机将采取技术手段中止或终止您对该第三方软件的继续使用。</p>
<p>  3.5【关于广告的特别说明】</p>
<p>您理解并同意，您在使用飞天云手机的服务过程中，将接触到以各种方式投放的商业性广告或其他类型的商业信息；且您理解并同意，飞天云手机可以通过电子邮件、站内信息、手机短信、微信、网站公告或其他方式向您发送促销信息、内容推荐或其他相关商业信息。</p>
<p>四、【违约责任】</p>
<p> 4.1您在使用本服务的过程中，不得进行以下违约行为：</p>
<p>4.1.1违反本协议约定的；</p>
<p>4.1.2违反法律、法规、规章、条例以及任何具有法律效力之规范规定的；</p>
<p>4.1.3破坏、影响飞天云手机对任何第三方提供本服务的；</p>
<p>4.1.4进行危害计算机网络安全的行为；</p>
<p>4.1.5对韵帆及关联第三方（包括授权方和合作方）提供的本协议服务、活动造成不良影响，侵犯韵帆及/或关联第三方及/或其他用户的正当利益的；</p>
<p>4.1.6被有关部门认定侵犯任何第三方的合法权益的；</p>
<p>4.1.7利用本服务获取非法利益的，包括但不限于通过转售、转让、授权等不正当行为牟利；</p>
<p>4.1.8其他侵犯韵帆合法利益的行为。</p>
<p>4.2【违约行为处理】</p>
<p>韵帆发现违约行为后，有权采取以下一项或多项措施处理：</p>
<p>4.2.1采取技术手段予以删除或断开相关的信息；</p>
<p>4.2.2采购包括但不限于中止或终止部分或全部本服务；</p>
<p>4.2.3韵帆无需向您退还任何费用，未使用的服务费用作为违约金归韵帆所有，您账号内的损失由您自行承担，造成韵帆损失的（包括但不限于律师费、诉讼费等），您也应予赔偿。</p>
<p>4.3【赔偿责任】</p>
<p>如用户的违约行为使韵帆及其关联公司造成损失，包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失，用户应当赔偿韵帆及其关联公司的上述全部损失。</p>
<p>您的违约行为导致任何第三方损害的，您应当独立承担责任，如因任何原因导致韵帆先行向第三方赔偿，您应该在收韵帆的通知后立即将赔偿金支付给韵帆。</p>
<p>五、【免责声明】</p>
<p>5.1您知悉并同意，您在飞天云手机平台上使用第三方软件的行为属于个人行为，韵帆不对用户个人行为的安全性、正当性承担责任，亦无需承担任何责任；</p>
<p>5.2您在使用第三方软件时，对相关内容应进行独立判断并合法使用，因您的使用行为造成任何自身损害及对第三方的损害，韵帆不承担任何责任；</p>
<p>5.3因电信或网通部门的通讯线路故障、网络故障、系统不稳定以及不可抗力等非韵帆原因造成您飞天云手机账号及第三方软件账号内财产等丢失、减少的，韵帆不承担任何责任。</p>
<p>5.4您在使用飞天云手机所提供的服务时，如遭受任何人身或财务的损失、损害或伤害，除法律有明确规定外，韵帆数字不承担任何责任。由于您将个人密码告知他人或与他人共享注册帐户，由此导致的任何个人资料泄露，韵帆数字不承担任何责任。</p>
<p>5.5用户理解并同意，如用户在韵帆运营的其他产品中存在违规行为导致用户账号被封禁，则用户无法继续使用（包括但不限于浏览、存储、同步、管理、分享和下载等）飞天云手机服务，同时飞天云手机有权不予退还用户剩余会员权益等增值服务对应的费用。</p>
<p>六、【知识产权】</p>
<p>6.1韵帆是飞天云手机的知识产权权利人，与本软件相关的所有信息内容均属于韵帆数字的知识产权，包括但不限于：本软件的文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等。任何未经韵帆书面同意并授权而擅自修改、发行、出租、翻译、分割、反向编译或反向工程本软件程序的全部或部分行为，均属于侵权行为，韵帆数字保留依法追究其法律责任的权利。</p>
<p>6.2本软件内由其他用户上传的软件、游戏、文字、图片等信息，您可浏览、下载、存储、使用，但不得从事侵犯知识产权的活动；</p>
<p>6.3您在分享游戏、软件、链接地址时，应在确认内容是合法的、不侵犯任何第三方知识产权后发布；</p>
<p>6.4您在浏览下载本软件中其他用户分享的信息时，发现有侵犯其他知识产权的行为，请您及时联系韵帆进行处理，非常感谢您的协助。</p>
<p>七、【服务的中止、终止及变更】</p>
<p>7.1 本服务的中止或终止包含如下情况：</p>
<p>7.1.1您主动中止或终止的，包括但不限于到期未进行续费等；</p>
<p>7.1.2您存在违约行为，韵帆主动中止或终止服务的；</p>
<p>7.1.3韵帆根据国家或相关部门要求或发生不可抗力事件而中止或终止服务；</p>
<p>7.1.4其他根据法律法规或本协议约定应中止或终止服务的情形。</p>
<p>7.2 中止或终止服务后，韵帆有权利但无义务确保您收到特别提示或通知。当您发现无法登录或享受服务时，可以咨询我们的客服人员。</p>
<p>7.3当发生7.1条约定的中止或终止情形时：</p>
<p>7.3.1除法律规定的责任外，韵帆不对您和任何第三人承担任何责任；</p>
<p>7.3.2已收取的费用不予退还；</p>
<p>7.3.3正在进行的平台交易，韵帆将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。</p>
<p>7.4 您同意，由于互联网服务的特殊性、合法合规要求或授权情况的变化，韵帆有权修改、变更、中断或终止部分或全部服务，或变更、删除、转移用户存储、发布在飞天云手机平台的内容（统称“变更行为”）。韵帆保留采取前述变更行为而不需通知您的权利，且您同意，依本协议任何规定提供之本服务，无需进行事先通知即可变更、中断或终止。并且您同意，由于变更行为而造成的任何损失，韵帆无需对您对或任何第三人承担任何责任。</p>
<p>八、【通知】</p>
<p>本协议项下韵帆对于用户所有的通知均可通过网页公告、站内消息、客户端推送、公众号通知、客户预留的电子邮件、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达用户。</p>
<p>为便于您收取相关通知，您应确保预留的联系方式为正确及有效，并在变更后及时登录腾讯视频进行修改；如因您未提供正确的联系信息或未及时告知新的联系方式，导致无法及时收取通知，将由您自行承担由此产生的损失及法律后果。</p>
<p>韵帆可通过上述方式之一或其中若干向您送达各类通知，而此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注相应通知。</p>
<p>九、【其他】</p>
<p>9.1 【协议的生效】</p>
<p>当您采用接受服务的形式进行下一步操作、使用或继续使用本服务，即表明您已阅读并同意受本协议的约束。</p>
<p>9.2 【协议签订地】</p>
<p>本协议签订地为中华人民共和国湖南省长沙市天心区。</p>
<p>9.3 【适用法律】</p>
<p>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</p>
<p>9.4 【争议解决】</p>
<p>若您和韵帆之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权韵帆数字所在地有管辖权的人民法院管辖</p>
<p>9.5 【条款标题】</p>
<p>本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</p>
<p>9.6 【条款效力】</p>
<p>本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</p>
<p>请用户在使用本软件时，仔细阅读并遵守本协议内容。</p>
<p>韵帆在法律允许的最大范围内对本协议拥有解释权与修改权。</p>

<p>韵帆数字（厦门）科技有限公司</p>


  </div>
</template>

<script>
export default {

}
</script>

<style>
    .agreement{
        margin: 100px 10px 0 10px;
        font-size: 20px;
        line-height: 30px;
    }
    .agreement h2{
        text-align: center;
    }
</style>