<template>
  <div class="agreement">

    <p class=MsoNormal align=center style='text-align:center;line-height:20.0pt'><b><span
    style='font-family:宋体'>飞天云手机隐私政策</span></b></p>

    <p class=MsoNormal align=center style='text-align:center;line-height:20.0pt'><span
    style='font-family:宋体'>更新日期</span><span lang=EN-US>:2024</span><span
    style='font-family:宋体'>年</span><span lang=EN-US>3</span><span style='font-family:
    宋体'>月</span><span lang=EN-US>5</span><span style='font-family:宋体'>日</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style='font-family:宋体'>欢迎您使用飞天云手机提供的服务！韵帆公司（本隐私政策中指韵帆数字</span><span lang=EN-US>(</span><span
    style='font-family:宋体'>厦门</span><span lang=EN-US>)</span><span
    style='font-family:宋体'>科技有限公司，注册地为福建省厦门市湖里区湖滨北路</span><span lang=EN-US>1106</span><span
    style='font-family:宋体'>号，以下亦称</span><span lang=EN-US>“</span><span
    style='font-family:宋体'>我们</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>）非常重视您的隐私保护和个人信息保护。本隐私政策适用于您通过任何方式对飞天提供的各项服务的访问和使用。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>您可以通过飞天云手机实现云手机使用、操作的各项服务（以下统称</span><span lang=EN-US>“</span><span
    style='font-family:宋体'>飞天</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>）。我们仅会处理您使用飞天所需的个人信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>本隐私政策适用于您通过飞天网页端、移动端（包括但不限于</span><span lang=EN-US>App</span><span
    style='font-family:宋体'>）等方式来访问和使用我们的产品服务。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>本隐私政策旨在帮助您了解我们会收集哪些个人信息、为什么收集这些个人信息，会利用这些个人信息做些什么及如何保护这些个人信息。我们会遵循隐私政策收集和使用您的个人信息，但不会仅因您授权同意本隐私政策而采用强制捆绑的方式收集您全部的个人信息。</span>
    <b><span style='font-family:宋体'>我们会严格按照法律法规要求，在特定情况下征求您的单独同意。</span></b> <span
    style='font-family:宋体'>本隐私政策约定了在不同场景下我们处理您的信息的方式，但只有当您开启相关功能或使用相关服务时，为实现相关功能、服务，我们才会处理您的信息。如您不开启相关功能或使用相关服务，则我们不会处理您对应的信息。</span><b>
    </b><b><span style='font-family:宋体'>除本隐私政策之外，我们还可能会通过产品页面告知、弹窗提示、信息推送等方式向您说明我们处理您个人信息的规则，具体业务功能对应的个人信息处理情况将以具体规则为准，具体规则与本隐私政策具有相同效力。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>除非法律、行政法规另有规定外，针对我们如何处理您的个人信息，您享有知情权和决定权，有权限制或者拒绝我们或其他方对您的个人信息进行处理；同时，您有权要求我们对我们向您提供的个人信息处理规则进行解释说明。请您在使用飞天及各项服务前仔细阅读并理解本隐私政策，以便做出适当的选择</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>本隐私政策将帮您了解以下内容：</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>一、我们收集的信息及相应的使用目的</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>二、我们如何使用</span><span lang=EN-US> Cookie </span></b><b><span
    style='font-family:宋体'>和同类技术</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>三、我们如何共享、转让、公开披露您的个人信息</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>四、如何行使您的个人信息相关权利</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>五、我们如何存储您的个人信息</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>六、我们如何保护您的个人信息</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>七、未成年人保护</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>八、本政策的修订</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp; </span></b><b><span
    style='font-family:宋体'>九、如何联系我们</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>一、我们收集的信息及相应的使用目的</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们将遵循合法、正当、必要的原则，收集和使用您的个人信息。具体而言，我们出于为您提供相应的业务功能而收集并处理您的个人信息。相应功能中，如果您提供的是他人个人信息，请您确保已取得相关主体的授权，我们保留随时核查该等个人信息合法来源的权利。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>您需要向我们提供个人信息以供我们实现飞天的基本业务功能。为实现基本业务功能所必需的信息为必要个人信息，若您拒绝提供这些必要个人信息，我们将无法向您提供该基本业务功能及相应服务。在基本业务功能之外，您可以自主选择向我们提供部分个人信息以供我们实现飞天的扩展业务功能。我们将向您告知使用的目的并基于您的授权同意或实现您选择的扩展业务功能必需的目的处理这些个人信息。您拒绝提供该等扩展业务功能信息，不会影响您使用我们的基本业务功能，但如您拒绝提供实现扩展业务功能必需的个人信息，我们将无法为您提供扩展业务功能。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; <b>&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>为方便您的理解，我们将所提供的业务功能、处理目的及其所需个人信息如下所列：</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span><span
    lang=EN-US>14 </span><span style='font-family:宋体'>岁以下（含）儿童的个人信息属于个人敏感信息。在您使用飞天的时候，需要向我们提供或允许我们收集必要信息以供我们向您提供服务，包括：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; <b>&nbsp;&nbsp;1.
    </b></span><b><span style='font-family:宋体'>您主动提供给我们的信息</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>1</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>帮助您成为我们的用户，您填写的注册信息，包括您<b><u>电话号码</u></b>，以用于注册、登录飞天云手机账户使用飞天云手机产品和服务，及我们的客服和售后为您提供咨询服务，并保障您的账户安全。如果您仅需使用浏览、了解飞天云手机等，您不需要注册成为我们的用户及提供上述信息；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>2</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>当您与我们联系时，我们可能会保存您的<b><u>通信或通话记录</u></b>和内容或您留下的<b><u>联系方式</u></b>，以便帮助您解决问题，或记录相关问题的处理方案及结果。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>3</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>请注意，当您使用飞天云手机的云游戏功能在线试玩第三方运营的手机游戏时，您可能需要注册或登录您的游戏账号，并且第三方手机游戏运营方可能收集、使用您的相关信息，以便您使用其提供的服务。<b>请您放心，飞天云手机并不会收集、使用任何您的游戏账号信息或其他您提供给第三方手机游戏运营方的信息。</b>关于第三方手机游戏运营方如何收集、使用您的个人信息，建议您参考第三方手机游戏运营方的相关服务协议及隐私政策。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>4</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>您可以使用第三方账号（如<b><u>微信、</u></b></span><b><u><span
    lang=EN-US>QQ</span></u></b><span style='font-family:宋体'>）登陆飞天云手机，此时，你将授权飞天云手机读取您在第三方账号注册的公开信息（如昵称、头像）。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    2. </span></b><b><span style='font-family:宋体'>我们在您使用服务过程中如何收集使用您的个人信息：</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们可能会收集关于您使用本服务过程中的信息，此类信息包括：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; <b>&nbsp;&nbsp;&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>（</span><span lang=EN-US>1</span></b><b><span
    style='font-family:宋体'>）注册及登录账号</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>为便于我们为您提供持续稳定的服务并保障您使用本服务的安全性，您可以通过手机号创建飞天通用账号，并且您可以完善您的账号信息。当注册、登录飞天通用账号时，您需向我们提供您的手机号码。您可以选择设置账号名称、头像、登录密码。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>您也可以使用第三方账号（如微信、百度、</span><span lang=EN-US>QQ</span><span
    style='font-family:宋体'>）登录飞天。此时，您将授权飞天获取您在该第三方账号注册的公开信息（包括您的昵称、头像及您授权的其他信息）。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    <b>&nbsp;&nbsp;</b></span><b><span style='font-family:宋体'>（</span><span
    lang=EN-US>2</span></b><b><span style='font-family:宋体'>）进行实名认证</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>为了满足法律法规的强制性要求、出于提供产品或服务所必需或者基于您对增强账号保护级别的需求，在您使用飞天某些服务时或您有进一步需求时，</span>
    <b><span style='font-family:宋体'>您需提供您的真实姓名、身份证号完成账号实名认证。为了验证您提供信息的准确性和完整性，或保障您的账号安全，我们可能会将您向韵帆公司提供的实名信息与法律法规允许的机构或政府机关授权的机构所保存的您的对应信息进行比对核验。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>3</span><span
    style='font-family:宋体'>）保证服务及运营安全</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>为提高您使用我们服务的安全性，保护您、其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或飞天平台相关协议规则的情况，经您授权，我们会收集或使用您的注册信息、设备信息（包括您用于安装、运行飞天的终端设备的设备属性信息，例如用户的硬件型号，操作系统版本及系统情况，设备配置，国际移动设备身份码</span><span
    lang=EN-US> IMEI</span></b><b><span style='font-family:宋体'>、国际移动用户识别码</span><span
    lang=EN-US>IMSI</span></b><b><span style='font-family:宋体'>、网络设备硬件地址</span><span
    lang=EN-US>MAC</span></b><b><span style='font-family:宋体'>、广告标识符</span><span
    lang=EN-US>IDFA</span></b><b><span style='font-family:宋体'>、供应商标识符</span><span
    lang=EN-US>IDFV</span></b><b><span style='font-family:宋体'>、移动设备识别码</span><span
    lang=EN-US>MEID</span></b><b><span style='font-family:宋体'>、匿名设备标识符</span><span
    lang=EN-US>OAID</span></b><b><span style='font-family:宋体'>、集成电路卡识别码</span><span
    lang=EN-US>ICCID </span></b><b><span style='font-family:宋体'>、</span><span
    lang=EN-US>Android ID</span></b><b><span style='font-family:宋体'>、硬件序列号等设备标识符）、设备连接信息（如浏览器的类型、电信运营商、使用的语言、</span><span
    lang=EN-US> WIFI</span></b><b><span style='font-family:宋体'>信息、</span><span
    lang=EN-US>BSSID</span></b><b><span style='font-family:宋体'>信息）以及设备状态信息、网络日志，以及您安装的应用列表信息、应用程序的总体运行、总体安装使用情况，来综合判断您账号及交易风险，用以检测及防范安全事件。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>（</span><span lang=EN-US>4</span></b><b><span
    style='font-family:宋体'>）优化服务体验，解决服务中的问题</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>当您使用飞天时，</span> </b><b><span
    style='font-family:宋体'>为了保障您正常使用飞天云手机服务，更准确定位并解决您在使用飞天云手机服务时遇到的问题，改进及优化飞天云手机的服务体验，我们会收集设备信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>（</span><span lang=EN-US>5</span></b><b><span
    style='font-family:宋体'>）记录您发出的请求</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>与大多数互联网服务一样，</span> <b><span
    style='font-family:宋体'>我们的服务器会自动记录您在访问飞天云手机时所发出的请求，包括您的</span><span lang=EN-US>IP
    </span></b><b><span style='font-family:宋体'>地址、浏览器的类型、硬件设备信息、操作系统的版本、网络运营商的信息、您访问服务的日期、时间、时长。请注意，这是我们为了保障软件与服务的正常运行所要收集的基本信息。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>（</span><span lang=EN-US>6</span></b><b><span
    style='font-family:宋体'>）增强云游戏体验功能</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>为了方便您更流畅地试玩游戏，提升您的游戏试玩体验，我们需要获取您的<b><u>设备位置信息</u></b>，以便您的设备位置就近分配服务资源。当您开启设备定位功能并授权飞天云手机访问您的<b><u>位置信息</u></b>，我们会使用各种技术进行定位，这些技术包括</span>
    <b><u><span lang=EN-US>IP</span></u></b><span lang=EN-US> </span><span
    style='font-family:宋体'>地址、</span><b><u><span lang=EN-US>GPS</span></u></b><span
    lang=EN-US> </span><span style='font-family:宋体'>以及能够提供相关信息的其他传感器（如可能会为我们提供附近设备、</span><b><u><span
    lang=EN-US>Wi-Fi</span></u></b><span lang=EN-US> </span><span style='font-family:
    宋体'>接入点和基站的信息）。<b>您可以随时通过取消系统授权飞天访问您的位置信息，停止我们对您的地理位置信息的收集，之后您可能将无法使用上述基于位置信息提供的相关服务或功能，或者无法达到上述基于位置信息提供的相关服务拟达到的效果，但不会影响您正常使用飞天云手机的其他业务功能。</b></span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>7</span><span
    style='font-family:宋体'>）当您进行信息分享或接收被分享的信息时，我们会通过读取<b>剪切板中的口令、链接等内容以实现信息内容的跳转、分享。</b></span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>8</span><span
    style='font-family:宋体'>）为了使您第一时间获取飞天活动信息、优惠资讯等，我们将使用您的<b><u>电话号码、设备信息、已获取的飞天产品信息</u></b>，为您提供商品或服务、活动信息的统一推送。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>9</span><span
    style='font-family:宋体'>）您使用图片上传、头像修改、语音对话功能时，我们会申请您设备系统的<b><u>相机、照片、录音权限</u></b>。您如果拒绝授权提供，将无法使用对应功能，但不影响您正常使用飞天不基于上述权限即可实现的其他功能。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>（</span><span lang=EN-US>10</span></b><b><span
    style='font-family:宋体'>）客服或其他用户响应功能</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>当您联系我们的客服或使用其他用户响应功能时（其他用户投诉和需求），我们需要您</span>
    <b><span style='font-family:宋体'>提供必要的身份信息以匹配并核验您的用户身份</span></b> <span
    style='font-family:宋体'>以便保障您的账号与系统安全。为处理您的诉求，人工客服需要在您授权范围内查询或核验您的相关信息并仅在必要范围内使用，如您拒绝提供或提供错误的，我们可能无法向您提供相应支持。在确认您用户身份后，我们还会</span>
    <b><span style='font-family:宋体'>保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的<u>通信</u></span><u><span
    lang=EN-US>/</span></u></b><b><u><span style='font-family:宋体'>通话记录和内容</span></u></b><b><span
    style='font-family:宋体'>、与您需求相关联的其他必要信息，</span></b> <span style='font-family:
    宋体'>以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>11</span><span
    style='font-family:宋体'>）为共同向您提供本服务或改进服务的质量或出于对产品和服务安全性的考量等合理需要，我们可能按照法律法规的规定或基于您的有效授权从飞天平台、关联公司、合作伙伴及其他受信任的第三方供应商处接收您的个人信息及其他信息。我们将事先要求个人信息提供方说明个人信息来源，并对其个人信息来源的合法性进行确认，同时，我们会了解个人信息提供方已获得的个人信息处理的授权同意范围，如开展业务所需进行的个人信息处理活动超出已获得的授权同意范围的，我们将在获取个人信息后的合理期限内或处理个人信息前，征得您的明示同意，或通过个人信息提供方征得您的明示同意。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>12</span><span
    style='font-family:宋体'>）个人信息的匿名化处理</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>在不公开披露、对外提供您个人信息的前提下，韵帆公司有权对匿名化处理后的用户数据库进行挖掘、分析和利用（包括商业性使用），有权对产品</span><span
    lang=EN-US>/</span><span style='font-family:宋体'>服务使用情况进行统计并与公众</span><span
    lang=EN-US>/</span><span style='font-family:宋体'>第三方共享匿名化处理后的统计信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>（</span><span lang=EN-US>13</span></b><b><span
    style='font-family:宋体'>）设备权限调用</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>在提供服务过程中，为了保证您能正常使用产品功能，我们会向系统申请您设备的以下系统设备权限，申请前我们会征询您的同意，您可以选择</span><span
    lang=EN-US>“</span></b><b><span style='font-family:宋体'>允许</span><span
    lang=EN-US>”</span></b><b><span style='font-family:宋体'>或</span><span
    lang=EN-US>“</span></b><b><span style='font-family:宋体'>禁止</span><span
    lang=EN-US>”</span></b><b><span style='font-family:宋体'>权限申请。经过您的授权后我们会开启相关权限，您可以随时在系统中取消授权，您取消授权会导致您无法使用相关的业务功能，但不会导致您无法使用其他业务功能。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>各项业务功能对系统隐私权限的调用情况如下：</span></b></p>

    <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
    style='border-collapse:collapse;border:none'>
    <tr style='height:25.75pt'>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt;height:25.75pt'>
      <p class=MsoNormal style='line-height:20.0pt'><b><span style='font-family:
      宋体'>设备权限</span></b></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border:solid black 1.0pt;
      border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:25.75pt'>
      <p class=MsoNormal style='line-height:20.0pt'><b><span style='font-family:
      宋体'>业务功能</span></b></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border:solid black 1.0pt;
      border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:25.75pt'>
      <p class=MsoNormal style='line-height:20.0pt'><b><span style='font-family:
      宋体'>权限授权方式</span></b></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border:solid black 1.0pt;
      border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:25.75pt'>
      <p class=MsoNormal style='line-height:20.0pt'><b><span style='font-family:
      宋体'>可否关闭</span></b></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>获取粗略位置</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>为用户分配更好的云手机服务器资源</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用地理位置相关业务功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>获取精确位置</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>为用户分配更好的云手机服务器资源</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用地理位置相关业务功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>读取手机状态和身份</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>向</span><span
      lang=EN-US>sdk</span><span style='font-family:宋体'>提供终端证明文件，保证功能的正常使用</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用相应功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>摄像头</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户在云手机中使用照相机功能时，可以调取用户手机的摄像头拍照和录像</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用摄像头拍摄时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>读取联系人</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>判断</span><span
      lang=EN-US>Uri</span><span style='font-family:宋体'>是否为本地通讯录中的图片</span><span
      lang=EN-US>Uri</span><span style='font-family:宋体'>，是则会读取通讯录中联系人头像</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用相应功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>读取外置存储卡</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户可将自己手机本地中的文件上传至云手机中，需读取外置存储卡信息</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用相应功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>麦克风</span><span
      lang=EN-US>/</span><span style='font-family:宋体'>录音</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户在云手机中使用语音功能时，可以调取用户手机的麦克风录音</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用录音相应功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>通讯录</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>判断</span><span
      lang=EN-US>Uri</span><span style='font-family:宋体'>是否为本地通讯录中的图片</span><span
      lang=EN-US>Uri</span><span style='font-family:宋体'>，否则会修改通讯录头像</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用通讯录相关业务功能</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>存储</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户通过拍照或上传文件的方式修改头像时，需要写入外部存储卡</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次打开</span><span
      lang=EN-US>APP</span><span style='font-family:宋体'>时</span><span lang=EN-US>/</span><span
      style='font-family:宋体'>存储相关业务功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    <tr>
      <td width=92 valign=top style='width:69.2pt;border:solid black 1.0pt;
      border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>WIFI</span><span
      style='font-family:宋体'>无线网络</span></p>
      </td>
      <td width=192 valign=top style='width:143.8pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>各项业务功能进行网络连接</span></p>
      </td>
      <td width=234 valign=top style='width:175.15pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>用户首次使用相应功能时弹窗询问</span></p>
      </td>
      <td width=51 valign=top style='width:37.95pt;border-top:none;border-left:
      none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
      padding:0cm 5.4pt 0cm 5.4pt'>
      <p class=MsoNormal style='line-height:20.0pt'><span style='font-family:宋体'>是</span></p>
      </td>
    </tr>
    </table>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>您可以通过飞天云手机</span><span
    lang=EN-US>App</span></b><b><span style='font-family:宋体'>【我的</span><span
    lang=EN-US>-</span></b><b><span style='font-family:宋体'>设置</span><span
    lang=EN-US>-</span></b><b><span style='font-family:宋体'>关于飞天</span><span
    lang=EN-US>-</span></b><b><span style='font-family:宋体'>隐私协议】查阅隐私权限详情并在【我的</span><span
    lang=EN-US>-</span></b><b><span style='font-family:宋体'>设置</span><span
    lang=EN-US>-</span></b><b><span style='font-family:宋体'>管理云手机权限】关闭权限（麦克风，摄像头）。您还可以通过您的设备提供的权限设置方式开启或关闭上述权限，具体请您参考设备及系统开发方说明或指引。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>自动化决策：自动化决策活动同样依赖于我们对您的使用行为信息、设备信息、位置信息、标签信息的处理。如果我们通过自动化决策方式作出对您的个人权益有重大影响的决定，您有权通过本政策【</span><span
    lang=EN-US>“</span></b><b><span style='font-family:宋体'>九、如何联系我们</span><span
    lang=EN-US>”</span></b><b><span style='font-family:宋体'>】要求我们予以说明，并有权拒绝我们仅通过自动化决策的方式作出决定。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    <b>&nbsp;3. </b></span><b><span style='font-family:宋体'>事先征得同意的例外：</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;<b>&nbsp;&nbsp;&nbsp;
    </b></span><b><span style='font-family:宋体'>根据相关法律法规规定，以下情形中处理您的信息无需征得您的授权同意：</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·与国家安全、国防安全直接相关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·为订立、履行个人作为一方当事人的合同所必需；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·为履行法定职责或者法定义务所必需；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·与犯罪侦查、起诉、审判和判决执行等直接有关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·依照法律法规的规定在合理的范围内收集您自行向社会公众公开或其他已经合法公开的个人信息；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·依照法律法规的规定在合理的范围内从合法公开披露的信息中收集您的个人信息，如合法的新闻报道、政府信息公开等渠道；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·法律法规规定的其他情形。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>二、我们如何使用</span><span lang=EN-US>COOKIES</span></b><b><span
    style='font-family:宋体'>或同类技术</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <b>Cookie</b></span><b><span style='font-family:宋体'>和匿名标识符工具</span></b><span
    style='font-family:宋体'>。</span><span lang=EN-US>Cookie</span><span
    style='font-family:宋体'>是支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制。当您使用本服务时，我们会向您的设备发送一个或多个</span><span
    lang=EN-US>Cookie</span><span style='font-family:宋体'>或匿名标识符。当您与本服务进行交互时，我们允许</span><span
    lang=EN-US>Cookie</span><span style='font-family:宋体'>或者匿名标识符发送给韵帆公司服务器。</span><span
    lang=EN-US>Cookie </span><span style='font-family:宋体'>通常包含标识符、站点名称以及一些号码和字符。运用</span><span
    lang=EN-US>Cookie</span><span style='font-family:宋体'>技术，韵帆公司能够省去您输入重复信息的步骤，为您提供更加周到的服务，或帮您判断您账户的安全性。</span><span
    lang=EN-US>Cookie</span><span style='font-family:宋体'>还可以帮助我们统计流量信息，分析页面设计。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们不会将</span><span lang=EN-US> Cookie </span><span
    style='font-family:宋体'>用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除</span><span lang=EN-US>
    Cookie</span><span style='font-family:宋体'>。有关详情，请参见</span><span lang=EN-US>AboutCookies.org</span><span
    style='font-family:宋体'>。您可以清除计算机上保存的所有</span><span lang=EN-US> Cookie</span><span
    style='font-family:宋体'>，大部分网络浏览器都设有阻止</span><span lang=EN-US> Cookie </span><span
    style='font-family:宋体'>的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于</span><span
    lang=EN-US>Cookie</span><span style='font-family:宋体'>的韵帆公司提供的服务或功能。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>您可以清除您的</span><span lang=EN-US>Cookie</span><span
    style='font-family:宋体'>，限制我们对</span><span lang=EN-US>Cookie</span><span
    style='font-family:宋体'>的使用。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; <b>&nbsp;&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>三、我们如何共享、转让、公开披露您的个人信息</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们的某些服务将由授权合作伙伴提供，仅为实现本隐私政策中声明的目的，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。除另行获得您的授权或法律法规另有要求，我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>（一）共享</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们非常重视保护您的个人信息。我们仅会出于合法、正当、必要、特定、明确的目的向第三方提供您的个人信息。接收我们提供个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    1.</span><span style='font-family:宋体'>为实现本政策中声明的目的，我们可能会接入第三方服务商提供的的应用程序（如以嵌入代码、插件、</span><span
    lang=EN-US>H5</span><span style='font-family:宋体'>、</span><span lang=EN-US>SDK</span><span
    style='font-family:宋体'>等形式），以便向您提供更好的客户服务和用户体验。为实现我们服务而可能委托处理或者共享个人信息的情形如下：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>1</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>用于韵帆公司认可的第三方账号登录；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>2</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>用于在您同意的情况下搜集设备信息和日志信息等；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>3</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>用于支持飞天功能模块；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>4</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>用于优化产品性能和服务质量，包括提高硬件配网能力、降低服务器成本、功能热修复、安全分析、优化服务、提升用户体验等；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>5</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>用于账号安全、产品加固相关服务，包括网络监测、域名解析、防劫持、反垃圾反作弊、加解密服务等；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>6</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>第三方</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>服务商。为保证向您提供本隐私政策目的所述的服务，我们可能会接入由第三方提供的软件开发包（</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>）以实现相关功能，用于更好地提高客户服务和用户体验。此时，这些第三方</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>服务商可能代表飞天或出于本隐私政策的一项或多项目的收集您的个人信息。目前，我们接入的第三方</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>主要包括以下几种类型：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    a. </span><span style='font-family:宋体'>一键登录功能：为给您提供账号登录</span><span lang=EN-US>—</span><span
    style='font-family:宋体'>一键登录功能，第三方</span><span lang=EN-US>SDK</span><span
    style='font-family:宋体'>服务商可能会获取您的必要设备信息、网络相关信息；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    b. </span><span style='font-family:宋体'>第三方账号登录功能：为给您提供第三方账号登录的功能，第三方</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>服务商可能会获取您的必要设备信息、网络相关信息；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    c. </span><span style='font-family:宋体'>账号安全服务：为保障您的账号安全，第三方</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>可能会收集您的必要设备信息、网络相关信息、日志信息、地理<b><u>位置信息</u></b>以提供账号安全服务；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    d. </span><span style='font-family:宋体'>广告相关服务，包括广告展示、广告统计及监控等；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    e. </span><span style='font-family:宋体'>支付服务：为保障您顺利购买平台上的商品</span><span
    lang=EN-US>/</span><span style='font-family:宋体'>服务、虚拟商品，第三方支付服务商（如支付宝</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>）可能会收集您的设备信息（如</span><span
    lang=EN-US>Android ID</span><span style='font-family:宋体'>、</span><span
    lang=EN-US>MAC</span><span style='font-family:宋体'>地址、</span><span lang=EN-US>OAID</span><span
    style='font-family:宋体'>）、网络信息、运营商信息以实现顺利完成支付以及保证支付安全。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    f. </span><span style='font-family:宋体'>产品模块性能优化，包括提高硬件配网能力、功能热修复、交互分析、网络性能及网络错误监控等。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    2. </span><span style='font-family:宋体'>我们服务可能接入或链接至韵帆公司其他产品或第三方提供的服务。包括：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>1</span><span
    style='font-family:宋体'>）您可利用</span><span lang=EN-US>“</span><span
    style='font-family:宋体'>分享</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>功能将某些本产品服务内容分享到韵帆公司其他产品或第三方产品或服务，或您将韵帆公司其他产品或服务或第三方产品或服务的内容分享到飞天；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>2</span><span
    style='font-family:宋体'>）通过服务公告或其他方式向您提供链接，使您可以链接至韵帆公司其他产品或服务、第三方的产品或服务或网站；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>3</span><span
    style='font-family:宋体'>）其他接入第三方服务的情形。例如，我们接入的第三方可能会收集您的个人信息，如您在我们的服务中使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息。我们会评估这类第三方服务收集个人信息的合法性、正当性、必要性，要求该等第三方对您的个人信息采取保护措施，并严格遵守相关法律法规与监管要求。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>以上第三方服务由相关的第三方负责运营。您使用该等第三方服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及个人信息保护相关规则（而非本政策）约束，您需要仔细阅读其条款。本政策仅适用于我们所收集的个人信息，并不适用于任何第三方提供的服务或第三方的信息使用规则。如您发现这些第三方服务存在风险时，建议您终止相关操作以保护您的合法权益并及时与我们取得联系。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    3.</span><span style='font-family:宋体'>为了更好地保护未成年人身心健康，引导未成年人健康上网，防止未成年人冒用、借用成年人的相关信息，我们将基于未成年人监护人的请求或我们主动保护未成年人的需要，可能会尝试联系其监护人，将其相关的服务账号、上网时长、上网行为等信息告知其监护人，进行提醒、确认和处理。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    4. </span><span style='font-family:宋体'>我们与我们的关联公司或同一帐号体系使用方之间具备高度一致的数据安全保障措施和管理水平。为了共同向您提供服务、提高您使用产品的安全性和便利性、保障一致性的服务体验，在遵循法律要求的前提下，我们会在关联公司或同一帐号体系使用方之间共享您的个人信息。在此情况下，我们只会共享必要的个人信息，如果我们共享您的敏感个人信息或接收方改变个人信息的使用及处理目的，将再次征求您的授权同意。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>对于同一帐号体系使用方，为了履行监管要求、保障帐号安全性、进行一致化的展示及能够与您取得联系，基于您的有效授权，我们可能会共享您的帐号相关信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（二）我们所接入的第三方软件开发工具包（</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>）</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>为了适配在不同手机设备上使用飞天云手机以及第三方平台服务，实现您的手机设备身份认证、手机设备安全、登录第三方平台账号、通过第三方平台分享信息以及支付安全，飞天接入了第三方</span><span
    lang=EN-US>SDK</span></b><b><span style='font-family:宋体'>。上述第三方</span><span
    lang=EN-US>SDK</span></b><b><span style='font-family:宋体'>提供方包括手机通讯运营商和第三方支付机构。部分第三方</span><span
    lang=EN-US>SDK</span></b><b><span style='font-family:宋体'>可能会调用您的设备权限，并收集您的设备信息（如网络设备硬件地址</span><span
    lang=EN-US>MAC</span></b><b><span style='font-family:宋体'>、广告标识符</span><span
    lang=EN-US>IDFA</span></b><b><span style='font-family:宋体'>、供应商标识符</span><span
    lang=EN-US>IDFV</span></b><b><span style='font-family:宋体'>、匿名设备标识符</span><span
    lang=EN-US>OAID </span></b><b><span style='font-family:宋体'>、集成电路卡识别码</span><span
    lang=EN-US>ICCID </span></b><b><span style='font-family:宋体'>、</span><span
    lang=EN-US>Android ID</span></b><b><span style='font-family:宋体'>、硬件序列号等设备标识符），以便您在不同的手机设备或第三方平台上正常使用相应功能。</span></b>
    <span style='font-family:宋体'>不同的第三方</span><u> <b><span lang=EN-US>SDK</span></b></u><span
    style='font-family:宋体'>所调用的设备权限类型以及所获得的信息可能不同，可能包括获取您的<b><u>位置信息</u></b>、读</span><span
    lang=EN-US>/</span><span style='font-family:宋体'>写您的外部存储卡、读取您的手机状态和身份、查看</span><b><u><span
    lang=EN-US>WLAN</span></u></b><span style='font-family:宋体'>连接、检索正在运行的应用</span><span
    lang=EN-US>/</span><span style='font-family:宋体'>蓝牙。关于第三方</span><b><u><span
    lang=EN-US>SDK</span></u></b><span style='font-family:宋体'>具体调用的设备权限类型，以及如何收集、使用您的个人信息，建议您参考第三方</span><b><u><span
    lang=EN-US>SDK</span></u></b><span style='font-family:宋体'>的相关服务协议及隐私政策。如果您希望进一步了解我们所接入的第三方</span><span
    lang=EN-US>SDK</span><span style='font-family:宋体'>情况，请您阅读</span><span
    lang=EN-US><a href="https://ftyunos.com/APPSDK"><b><span lang=EN-US
    style='font-family:宋体;color:red'><span lang=EN-US>第三方</span></span></b><b><span
    style='color:red'>SDK</span></b><b><span lang=EN-US style='font-family:宋体;
    color:red'><span lang=EN-US>清单</span></span></b><b><span lang=EN-US
    style='font-family:宋体;color:red'><span lang=EN-US>。</span></span></b></a></span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; <b>&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>（三）委托处理与共同处理</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>为了更好地为您提供服务，我们可能委托第三方合作伙伴处理您的个人信息。我们仅会出于合法、正当、必要、特定、明确的目的委托第三方合作伙伴处理您的信息。我们会严格限制受托方的信息处理目的并要求受托方采取充分的数据安全保障手段。如果第三方合作伙伴将您的信息用于我们未委托的用途，其将另行征得您的同意。我们委托的授权合作伙伴包括广告、分析服务类的授权合作伙伴。我们会在遵守行业通行的安全技术前提下，委托这些合作伙伴处理与广告投放、广告效果、广告触达率有关的信息。未经您有效授权，我们不会将可以直接识别您个人身份的信息委托第三方合作伙伴处理。如您主动为特定第三方合作伙伴留下您的联系方式、地址信息等个人信息以供其与您取得联系，其将直接处理您的个人信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>在共同处理场景下，我们同样会按照法律法规要求与合作方签署符合法律法规要求的相关协议，并履行相关个人信息保护义务。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; <b>&nbsp;&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>在以下情形中，共享、转让、公开披露个人信息无需事先征得个人信息主体的授权同意：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·与国家安全、国防安全直接相关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·为订立、履行个人作为一方当事人的合同所必需；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·为履行法定职责或者法定义务所必需；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·与公共安全、公共卫生、重大公共利益直接相关的。例如：为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·依照法律法规的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息，例如：合法的新闻报道、政府信息公开等渠道等；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·法律法规规定的其他情形。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; <b>&nbsp;</b></span><b><span
    style='font-family:宋体'>四、如何行使您的个人信息相关权利</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>在您使用飞天期间，我们将根据飞天的具体情况为您提供管理您的个人信息的相应操作设置，以便您可以查阅、复制、转移、更正、补充、删除或撤回您的相关个人信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    1.<b> </b></span><b><span style='font-family:宋体'>查询权</span></b> <span
    style='font-family:宋体'>您有权查询您的个人信息，法律法规规定的情形例外除外。</span> <span
    style='font-family:宋体'>您可以通过</span><span lang=EN-US>“</span><span
    style='font-family:宋体'>飞天云手机</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>我的</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>头像</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>编辑资料</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>查询或编辑您的账户中的个人资料等信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    2. </span><b><span style='font-family:宋体'>更正权</span> </b><span
    style='font-family:宋体'>当您发现我们处理关于您的个人信息有错误时，您可以通过联系在线客服进行信息修改。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    3. </span><b><span style='font-family:宋体'>删除权</span></b> <span
    style='font-family:宋体'>以下情形下，您可以联系在线客服或在我的</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>设置</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>关于飞天云</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>注销账号删除个人信息的请求：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>1</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>如果我们处理个人信息的行为违反法律法规；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>2</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>如果我们收集、使用您的个人信息，却未征得您的同意；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>3</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>如果我们处理个人信息的行为违反了与您的约定；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>4</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>如果您不再使用我们的产品或服务，或您注销了账号；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>5</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>如果我们不再为您提供产品或服务。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    4. </span><b><span style='font-family:宋体'>撤销权</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>1</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>对于您个人主动提供的信息，您可以在您的个人账户</span><span
    lang=EN-US>“</span><span style='font-family:宋体'>飞天</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>个人中心</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>头像</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>个人资料</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>中进行撤销您的个人信息；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>2</span><span
    style='font-family:宋体'>）对于您授权给我们的信息，您可以在</span><span lang=EN-US>“</span><span
    style='font-family:宋体'>飞天</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>个人中心</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>设置</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>管理云手机权限</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>中进行撤销。<b>请您注意，不同的设备中，路径可能有所不同，具体参考您的设备及系统开发方的说明和指引。</b></span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>当撤回授权后，我们无法继续为您提供撤回授权所对应的服务，也不再处理您相应的信息。但您撤回授权的决定，不会影响此前基于您的授权而开展的信息处理。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    5. </span><b><span style='font-family:宋体'>注销权</span></b> <span
    style='font-family:宋体'>如果您决定停止使用飞天的，则您可以对您的账号进行注销。账号注销可以在</span><span
    lang=EN-US> “</span><span style='font-family:宋体'>飞天官网</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>帮助</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>账号注销</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>中实现，在成功提交注销申请后，</span><span lang=EN-US>15</span><span
    style='font-family:宋体'>天无登录行为则完成注销。您也可以在</span><span lang=EN-US>“</span><span
    style='font-family:宋体'>飞天</span><span lang=EN-US>-</span><span
    style='font-family:宋体'>客服中心</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>通过在线客服协助下进行注销。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>申请注销后，我们将在</span><span
    lang=EN-US>3</span></b><b><span style='font-family:宋体'>个工作日内做出响应。注销账号之后此账号内的数据无法恢复，包括但不限于账户内的资产信息如红币、红豆、优惠券、电子钱包余额等，帐号信息内容也将全部清除。该注销操作不能撤销，请您务必谨慎申请。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    6. </span></b><b><span style='font-family:宋体'>复制权及可携带权</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>您有权复制我们收集的您的个人信息。在法律法规规定的条件下，同时符合国家网信部门规定指令和条件的，如果技术可行，您也可以要求我们将您的个人信息转移至您指定的其他主体。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    7. </span><span style='font-family:宋体'>提前获知产品和服务停止运营权</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>若因特殊原因导致本服务停止运营，我们将在合理期间内在产品或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对已收集的您的个人信息进行删除或匿名化处理。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    8. </span><span style='font-family:宋体'>在以下情形中，按照法律法规的要求，我们将无法响应您的上述请求：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>1</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>与个人信息控制者履行法律法规规定的义务相关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>2</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>与国家安全、国防安全直接相关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>3</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>与公共安全、公共卫生、重大公共利益直接相关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>4</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>与刑事侦查、起诉、审判和执行判决等直接相关的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>5</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>6</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>7</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>（</span><span lang=EN-US>8</span><span
    style='font-family:宋体'>）</span> <span style='font-family:宋体'>涉及商业秘密的。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    9. </span><span style='font-family:宋体'>如您有其他查阅、复制、转移、更正、补充或删除需求或您在使用本服务期间有任何疑问或困难，可以选择通过本政策</span><b><span
    lang=EN-US>“</span></b><b><span style='font-family:宋体'>如何联系我们</span><span
    lang=EN-US>”</span></b><span style='font-family:宋体'>章节所列的反馈渠道联系我们，我们会在<b>验证您的用户身份后的十五个工作日内予以回复。</b></span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    10. </span><span style='font-family:宋体'>除法律法规另有规定，当您更正、补充、删除您的个人信息或申请注销账号时，我们可能不会立即从备份系统中更正、补充或删除相应的信息，但会在备份更新时更正、补充或删除这些信息。</span><b>
    </b><b><span style='font-family:宋体'>相关信息一旦被删除或者账号被注销，将无法恢复，请您谨慎操作。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; <b>&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>五、我们如何存储您的个人信息</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    1. </span><span style='font-family:宋体'>保存期限</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们将在提供服务所需的期限内保存您的个人信息，但法律法规对保存期限另有规定、您同意留存更长的期限、保证服务的安全与质量、实现争议解决目的、技术上难以实现等情况下，在前述保存期限到期后，我们将依法、依约或在合理范围内延长保存期限。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>在超出保存期限后，我们将根据法律规定删除您的个人信息或进行匿名化处理。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    2. </span><span style='font-family:宋体'>保存地域</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。如您的个人信息可能会被转移到您使用产品或服务所在国家</span><span
    lang=EN-US>/</span></b><b><span style='font-family:宋体'>地区的境外管辖区，或者受到来自这些管辖区的访问，我们会严格履行法律法规规定的义务并按照法律规定事先获得您的单独同意。</span></b>
    <span style='font-family:宋体'>此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会按照中国现行法律的规定传输您的个人信息，并会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;<b>&nbsp;&nbsp;
    </b></span><b><span style='font-family:宋体'>六、我们如何保护您的个人信息</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们非常重视信息安全，成立了专门的安全团队，并采取一切合理可行的措施，保护您的个人信息：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    <b>1. </b></span><b><span style='font-family:宋体'>数据安全技术措施</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; </span><span
    style='font-family:宋体'>·我们会以</span><span lang=EN-US>“</span><span
    style='font-family:宋体'>最小化</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;</span><span
    style='font-family:宋体'>·我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。</span>
    <b><span style='font-family:宋体'>若有违反保密协议的行为，会被立即终止与韵帆公司的合作关系，</span></b><span
    style='font-family:宋体'>并会被追究相关法律责任，对接触个人信息人员在离岗时也提出了保密要求。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·我们提醒您注意，互联网并非绝对安全的环境，</span> <b><span style='font-family:
    宋体'>当您通过飞天中嵌入</span><span lang=EN-US>/</span></b><b><span style='font-family:
    宋体'>安装的第三方社交软件、电子邮件、短信等与其他用户交互您的地理位置或行踪轨迹信息时，不确定第三方软件对信息的传递是否完全加密，注意确保您个人信息的安全。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。</span>
    <b><span style='font-family:宋体'>请您了解，您使用我们的产品和</span><span lang=EN-US>/</span></b><b><span
    style='font-family:宋体'>或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><b><span
    style='font-family:宋体'>·根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为最特大安全事件，一经发生将启动公司最高级别的紧急预案，</span></b>
    <span style='font-family:宋体'>由安全部、法务部等多个部门组成联合应急响应小组处理。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;<b>2. </b></span><b><span
    style='font-family:宋体'>安全事件通知</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; </span><span
    style='font-family:宋体'>·我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; </span><span
    style='font-family:宋体'>·个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; </span><span
    style='font-family:宋体'>·在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。请您理解，根据法律法规的规定，如果我们采取的措施能够有效避免信息泄露、篡改、丢失造成危害的，除非监管部门要求向您通知，我们可以选择不向您通知该个人信息安全事件。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; <b>&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>七、未成年人保护</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp; <b>&nbsp;&nbsp;&nbsp;</b></span><b><span
    style='font-family:宋体'>我们的产品和服务主要面向成年人。我们只会在受到法律法规允许、父母或其他监护人同意的情况下处理未成年的个人信息。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>如您是未满</span><span lang=EN-US>14</span></b><b><span
    style='font-family:宋体'>周岁的未成年人，在使用飞天前，应请您的父母或其他监护人仔细阅读，或在您的父母或其他监护人的监护、指导下共同仔细阅读本隐私政策以及</span></b><span
    lang=EN-US><a href="https://www.ftyunos.com/PrivacyChildrenAgreement"><b><span
    lang=EN-US style='font-family:宋体'><span lang=EN-US>《</span></span></b><b><span
    lang=EN-US style='font-family:宋体'><span lang=EN-US>飞天</span></span></b><b><span
    lang=EN-US style='font-family:宋体'><span lang=EN-US>儿童用户隐私政策》</span></span></b><b><span
    lang=EN-US><span lang=EN-US> </span></span></b></a></span><b><span
    style='font-family:宋体'>并在征得您的父母或其他监护人同意的前提下使用我们的产品和服务，或向我们提供信息。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未满</span><span
    lang=EN-US>14</span></b><b><span style='font-family:宋体'>周岁的未成年人的个人信息，我们将会采取措施尽快删除相关信息。如果任何时候监护人有理由相信我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过飞天【我的】</span><span
    lang=EN-US>-</span></b><b><span style='font-family:宋体'>【联系方式】</span><span
    lang=EN-US>-</span></b><b><span style='font-family:宋体'>【联系客服】联系我们，我们会采取措施尽快删除相关数据。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; <b>&nbsp;</b></span><b><span
    style='font-family:宋体'>八、本政策的修订</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>我们的隐私政策可能变更。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本隐私政策所做的任何变更。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>对于重大变更，我们会在产品或服务的主要曝光页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您。</span>
    </b><b><span style='font-family:宋体'>若您不同意该等变更，请您立即停止使用飞天产品和服务。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>本政策所指的重大变更包括但不限于：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·个人信息对外提供、转让或公开披露的主要对象发生变化；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><span
    style='font-family:宋体'>·个人信息安全影响评估报告表明存在高风险时。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>九、如何联系我们</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    1. </span><span style='font-family:宋体'>飞天的成长离不开各方用户达人的共同努力，我们非常感谢您对飞天数据更新、使用反馈方面的贡献。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    2. </span><b><span style='font-family:宋体'>您对飞天或本协议的任何部分若有意见或建议可通过公司客户服务部门（电话：</span><span
    lang=EN-US>13194092328</span></b><b><span style='font-family:宋体'>；邮箱：</span><span
    lang=EN-US>missamykimo@yahoo.com.tw</span></b><b><span style='font-family:宋体'>）与我们联系。</span></b>
    <span style='font-family:宋体'>您也可以通过如下联络方式同我们联系：</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>福建省厦门市湖里区悦华路</span><span lang=EN-US>151-1</span><span
    style='font-family:宋体'>号</span><span lang=EN-US>719-3</span><span
    style='font-family:宋体'>室</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>韵帆数字</span><span lang=EN-US>(</span><span
    style='font-family:宋体'>厦门</span><span lang=EN-US>)</span><span
    style='font-family:宋体'>科技有限公司</span> <span style='font-family:宋体'>客服部</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    3. </span><span style='font-family:宋体'>一般情况下，我们将在收到您的问题、意见或建议，并验证您的用户身份后的十五个工作内予以回复。</span>
    <b><span style='font-family:宋体'>有关本协议的争议应通过友好协商解决，如果协商不成，向消费者权益保护部门投诉或向韵帆公司所在地（福建省厦门市湖里区）有管辖权的法院提起诉讼。</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><b><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span></b><b><span style='font-family:宋体'>附：名词解释</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>个人信息</span> <span style='font-family:宋体'>是指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、<b><u>通信通讯联系方式、通信记录和内容</u></b>、帐号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息等。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;
    <b>&nbsp;</b></span><b><span style='font-family:宋体'>敏感个人信息</span></b> <span
    style='font-family:宋体'>是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>设备是指可用于使用飞天产品和</span><span lang=EN-US>/</span><span
    style='font-family:宋体'>或服务的装置，例如桌面设备、平板电脑或智能手机。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>设备信息</span></b> <span style='font-family:
    宋体'>可能包括您用于安装、运行飞天的终端设备的设备属性信息（例如硬件型号，操作系统版本及系统情况，设备配置，国际移动设备身份码</span><span
    lang=EN-US>IMEI</span><span style='font-family:宋体'>、国际移动用户识别码</span><span
    lang=EN-US>IMSI</span><span style='font-family:宋体'>、网络设备硬件地址</span><span
    lang=EN-US>MAC</span><span style='font-family:宋体'>、广告标识符</span><span
    lang=EN-US>IDFA</span><span style='font-family:宋体'>、供应商标识符</span><span
    lang=EN-US>IDFV</span><span style='font-family:宋体'>、移动设备识别码</span><span
    lang=EN-US>MEID</span><span style='font-family:宋体'>、匿名设备标识符</span><span
    lang=EN-US>OAID </span><span style='font-family:宋体'>、集成电路卡识别码</span><span
    lang=EN-US>ICCID </span><span style='font-family:宋体'>、</span><span lang=EN-US>Android
    ID</span><span style='font-family:宋体'>、硬件序列号等设备标识符）、设备连接信息（例如浏览器的类型、电信运营商、使用的语言、</span><span
    lang=EN-US>WIFI</span><span style='font-family:宋体'>信息）以及设备状态信息（例如设备应用安装列表、设备传感器信息）。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>日志信息</span></b> <span style='font-family:
    宋体'>是指我们的服务器所自动记录您在访问飞天时所发出的请求，例如您的</span><span lang=EN-US>IP </span><span
    style='font-family:宋体'>地址、浏览器的类型和使用的语言、硬件设备信息、操作系统的版本、网络运营商的信息、您访问服务的日期、时间、时长等您在使用我们的产品或服务时提供、形成或留存的信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; <b>&nbsp;Cookie</b>
    </span><span style='font-family:宋体'>是指支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制，通过增加简单、持续的客户端状态来扩展基于</span><span
    lang=EN-US>Web</span><span style='font-family:宋体'>的客户端</span><span lang=EN-US>/</span><span
    style='font-family:宋体'>服务器应用。服务器在向客户端返回</span><span lang=EN-US>HTTP</span><span
    style='font-family:宋体'>对象的同时发送一条状态信息，并由客户端保存。状态信息中说明了该状态下有效的</span><span
    lang=EN-US>URL</span><span style='font-family:宋体'>范围。此后，客户端发起的该范围内的</span><span
    lang=EN-US>HTTP</span><span style='font-family:宋体'>请求都将把该状态信息的当前值从客户端返回给服务器，这个状态信息被称为</span><span
    lang=EN-US>Cookie</span><span style='font-family:宋体'>。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><b><span
    style='font-family:宋体'>位置信息</span></b> <span style='font-family:宋体'>是指通过</span><span
    lang=EN-US>GPS</span><span style='font-family:宋体'>信息，</span><span lang=EN-US>WLAN</span><span
    style='font-family:宋体'>接入点、蓝牙、基站以及其他传感器信息所获取的精确位置信息，也包括通过</span><span
    lang=EN-US>IP</span><span style='font-family:宋体'>地址或其他网络信息等获取的粗略地理位置信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp; </span><b><span
    style='font-family:宋体'>用户画像</span></b> <span style='font-family:宋体'>是指通过收集、汇聚、分析个人信息，对某特定自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>去标识化</span></b> <span style='font-family:
    宋体'>是指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span><span style='font-family:宋体'>匿名化是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。个人信息经匿名化处理后所得的信息不属于个人信息。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>关联公司</span></b> <span style='font-family:
    宋体'>是指飞天的经营者韵帆数字</span><span lang=EN-US>(</span><span style='font-family:宋体'>厦门</span><span
    lang=EN-US>)</span><span style='font-family:宋体'>科技有限公司及其他与公司存在关联关系的公司的单称或合称。</span><span
    lang=EN-US>“</span><span style='font-family:宋体'>关联关系</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>是指对于任何主体（包括个人、公司、合伙企业、组织或其他任何实体）而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述</span><span
    lang=EN-US>“</span><span style='font-family:宋体'>控制</span><span lang=EN-US>”</span><span
    style='font-family:宋体'>指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策作出指示或责成他人作出指示的权力或事实上构成实际控制的其他关系。</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;&nbsp;&nbsp;
    </span><b><span style='font-family:宋体'>再次感谢您对飞天的信任和使用！</span></b></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal align=right style='text-align:right;line-height:20.0pt'><span
    style='font-family:宋体'>韵帆数字</span><span lang=EN-US>(</span><span
    style='font-family:宋体'>厦门</span><span lang=EN-US>)</span><span
    style='font-family:宋体'>科技有限公司</span></p>

    <p class=MsoNormal align=right style='text-align:right;line-height:20.0pt'><span
    lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal align=right style='text-align:right;line-height:20.0pt'><span
    style='font-family:宋体'>更新日期：【</span><span lang=EN-US> 2024 </span><span
    style='font-family:宋体'>年</span><span lang=EN-US> 03 </span><span
    style='font-family:宋体'>月</span><span lang=EN-US> 05 </span><span
    style='font-family:宋体'>日</span> <span style='font-family:宋体'>】</span></p>

    <p class=MsoNormal align=right style='text-align:right;line-height:20.0pt'><span
    lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal align=right style='text-align:right;line-height:20.0pt'><span
    style='font-family:宋体'>生效日期：【</span><span lang=EN-US>2024 </span><span
    style='font-family:宋体'>年</span><span lang=EN-US> 03 </span><span
    style='font-family:宋体'>月</span><span lang=EN-US> 05 </span><span
    style='font-family:宋体'>日】</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

    <p class=MsoNormal style='line-height:20.0pt'><span lang=EN-US>&nbsp;</span></p>

  </div>
</template>

<script>
export default {

}
</script>

<style>
    .agreement{
        margin: 100px 10px 0 10px;
        font-size: 20px;
        line-height: 30px;
    }
</style>