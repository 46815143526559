<template>
  <div class="dev">
    <el-card class="box-card">
      <div slot="header" class="clearfix"><span>设备筛选</span></div>
      <el-form
        ref="this.$store.state.form"
        :model="this.$store.state.form"
        label-width="100px"
        label-suffix=":"
        size="small"
        align="center"
      >
        <el-form-item label="设备种类">
          <el-radio-group v-model="checkdData.devType" @change="getData()">
            <el-radio-button label="iPhone" />
            <el-radio-button label="iPad" />
            <el-radio-button label="iPad Air" />
            <el-radio-button label="iPad Pro" />
            <el-radio-button label="iPad mini" />
            <el-radio-button label="iPod touch" />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="设备名称">
          <el-radio-group v-model="checkdData.devName" @change="getData()">
            <el-radio-button
              v-for="item in this.$store.state.form.devName"
              :label="item"
            />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="产品类型">
          <el-radio-group v-model="checkdData.proType" @change="getData()">
            <el-radio-button
              v-for="item in this.$store.state.form.proType"
              :label="item"
            />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="固件版本">
          <el-radio-group v-model="checkdData.firVer" @change="getData()">
            <el-radio-button
              v-for="item in this.$store.state.form.firVer"
              :label="item"
            />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="可否越狱">
          <el-radio-group v-model="checkdData.isRoot" @change="getData()">
            <el-radio-button label="不限" />
            <el-radio-button label="可越狱" />
            <el-radio-button label="不可越狱" />
          </el-radio-group>
        </el-form-item>

        <el-form-item label="可否刷机">
          <el-radio-group v-model="checkdData.isFlash" @change="getData()">
            <el-radio-button label="不限" />
            <el-radio-button label="可刷机" />
            <el-radio-button label="不可刷机" />
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { getFirstData, getFromData } from "@/network/ipsw/Ipsw_request.js";
export default {
  name: "Dev",
  created() {
    self = this; //解决在js文件 提交不到 Vuex
    getFirstData().then(() => {
      //首次网络请求
      this.checkdData.devName = this.$store.state.form.devName[0];
    });
  },
  data() {
    return {
      checkdData: {
        //离线数据
        devType: "iPhone",
        devName: null,
        proType: null,
        firVer: null,
        isRoot: "不限",
        isFlash: "不限",
      },
    };
  },
  methods: {
    getData() {
      //1、确认所需参数
      let args = [];
      if (this.checkdData.devType != null) args.push("category");
      if (this.checkdData.devName != null) args.push("name");
      if (this.checkdData.proType != null) args.push("type");
      if (this.checkdData.firVer != null) args.push("version");
      if (this.checkdData.isRoot == "可越狱" || this.checkdData.isRoot == "不可越狱" ) args.push("root");
      if (this.checkdData.isFlash == "可刷机" || this.checkdData.isFlash == "不可刷机") args.push("signed");

      args.filter((n) => n == "root");
      args.filter((n) => n == "signed");

      let formData = {};
      //2、生成请求参数
      if (this.arrFind(args, "category"))
        formData = { ...formData, category: this.checkdData.devType };

      if (this.arrFind(args, "name"))
        formData = { ...formData, name: this.checkdData.devName };

      if (this.arrFind(args, "type"))
        formData = { ...formData, type: this.checkdData.proType };

      if (this.arrFind(args, "version"))
        formData = { ...formData, version: this.checkdData.firVer };

      if (this.arrFind(args, "root")) {
        formData = {
          ...formData,
          root: this.checkdData.isRoot == "可越狱" ? 1 : 0,
        };
      }
      if (this.arrFind(args, "signed")) {
        formData = {
          ...formData,
          signed: this.checkdData.isFlash == "可刷机" ? 1 : 0,
        };
      }
      getFromData(formData).then(() => {
         this.checkdData.devName = this.$store.state.form.devName[0];
      });
    },
    arrFind(args, value) {
      if (args.indexOf(value) > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>


<style scoped>
.dev {
  padding: 50px 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-card {
  width: 1400px;
  padding-bottom: 50px;
}

.el-radio-group {
  width: 1000px;
  text-align: left;
}
</style>
