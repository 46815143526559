<template>
  <div class="agreement">
    <h2>飞天儿童隐私政策</h2>
<p>    韵帆数字（厦门）科技有限公司（下称“我们”或“公司”）深知保护儿童个人信息的重要性，我们致力于保护儿童的隐私和信息保护，遵守法律法规，特制定《飞天儿童用户隐私政策》。本政策旨在向法定监护人或儿童的父母（以下简称“监护人”或“您”）和儿童说明我们如何收集、使用、存储和处理儿童的个人信息，以及控制儿童个人信息的方式。 请监护人仔细阅读并向儿童解释以下条款，特别提醒监护人应仔细阅读本《飞天儿童用户隐私政策》中加粗、加下划线形式的条款内容，确认同意全部条款后再开始使用。如果监护人不同意本《飞天儿童用户隐私政策》，儿童将可能无法正常使用我们的产品、服务。本政策正文中的“您”指儿童。</p>

<p>监护人特别说明：</p>

<p>     请监护人仔细阅读和选择是否同意本《飞天儿童用户隐私政策》，我们希望监护人与我们共同保护儿童的个人信息，教育、引导儿童增强个人信息保护意识和能力，指导、提醒和要求他们在未经监护人同意的情况下，不向任何飞天产品和服务提供者提供任何个人信息。如您不同意本隐私政策，您应要求您的孩子立即停止使用飞天的服务，并拒绝提供儿童个人信息。您于线上点击同意本政策，或者您的孩子使用/继续使用飞天服务、提交个人信息，即表示您已同意我们按照本隐私政策来收集、使用、存储和保护您孩子相关的个人信息。</p>

<p>儿童特别说明：</p>

<p>     儿童在使用飞天产品和服务或提供任何个人信息前，请和监护人一起仔细阅读本《飞天儿童用户隐私政策》，并在征得监护人同意后，使用我们的产品、服务或向我们提供信息。您于线上点击同意本政策，或者您使用/继续使用飞天服务、提交个人信息，即表示您已获得您的监护人的许可，您的监护人已同意我们按照本隐私政策来收集、使用、存储和保护您相关的个人信息。        </p>
<p>     我们根据您填写个人资料或您提供的其他信息判断您的儿童身份，我们理解儿童信息属于个人敏感信息，我们根据国家相关法律法规的规定，保护儿童的个人信息，只会在法律法规允许、监护人明确同意的情况下收集、存储、使用、转移、披露您的信息，并遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则。对于未经监护人同意而未成年人用户提供的个人信息，在接到其监护人的通知后，我们会及时删除相关信息。</p>
<p>        本隐私政策将帮您了解以下内容：    </p>

<p>     一、适用范围    </p>
<p>     二、我们如何收集和使用儿童的个人信息  </p>
<p>     三、我们如何使用 Cookie 和同类技术    </p>
<p>     四、我们如何共享、转让、公开披露儿童的个人信息  </p>
<p>     五、监护人和儿童如何管理自己的信息及账号注销  </p>
<p>     六、我们如何存储儿童的个人信息</p>
<p>     七、我们如何保护儿童的个人信息</p>
<p>     八、本政策的修订    </p>
<p>     九、争议管辖    </p>

<p>一、适用范围</p>

<p>     1. 本隐私政策适用于除某些特定产品和服务外飞天提供的所有服务。这些特定产品和服务将适用特定的隐私政策。</p>

<p>     2. 需要特别说明的是，本隐私政策不适用于除本隐私政策第四条约定经您确认的与第三方共享信息外其他第三方向您提供的服务。</p>

<p>     请您知悉：如果您浏览第三方网站或使用第三方产品、服务时向其提供个人信息，则您的信息应当适用该第三方的隐私政策，我们不承担任何法律责任，请您届时务必仔细阅读该第三方的隐私政策并确认是否接受。</p>

<p>二、我们如何收集和使用儿童的个人信息  </p>

<p>   个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。14 岁以下（含）儿童的个人信息属于个人敏感信息。我们提供的产品和服务需要依赖您的部分信息才得以运行，因此，在您使用飞天的时候，需要在征得监护人同意的情况下，向我们提供或允许我们收集的必要信息以供我们使用这些信息向您提供基本服务及更优更好的个性化服务，包括：</p>

<p>     1. 儿童经监护人的同意后主动提供给我们的信息</p>

<p>       （1） 帮助您成为我们的用户，您填写的注册信息，包括您电话号码，以用于注册、登录飞天账户使用飞天产品和服务，及我们的客服和售后为您提供咨询服务，并保障儿童的账户安全。如果您仅需使用浏览、了解飞天等，您不需要注册成为我们的用户及提供上述信息；</p>

<p>       （2） 当您与我们联系时，我们可能会保存您的通信或通话记录和内容或您留下的联系方式，以便帮助您解决问题，或记录相关问题的处理方案及结果。我们的客服会使用您的帐号信息与您核验您的身份。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息。</p>

<p>      2. 我们在您使用服务过程中收集的信息，除法律规定情形外我们都将事先征得您的同意：</p>

<p>     （1） 在您首次使用打开飞天app时，我们将在取得您的授权后，接收并记录您所使用的设备相关信息，包括设备属性信息（如IMEI、IMSI、设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备位置信息（如通过GPS、蓝牙或WIFI信号获得的位置信息）、设备连接信息（如IP地址、电信运营商、访问日期和时间等）等，以保障您的账户及交易安全；</p>

<p>     （2） 在您首次使用打开飞天app时，我们将在您授权后读取您的读取手机状态，用以保障飞天APP应用服务的正常使用，更准确定位和解决在使用时遇到的问题，改进及优化飞天APP服务体验，及保障您的账号安全；</p>

<p>     3. 事先征得同意的例外：</p>

<p>     （1） 与个人信息控制者履行法律法规规定的义务相关的；</p>
<p>     （2） 与国家安全、国防安全直接相关的；</p>
<p>     （3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
<p>     （4） 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
<p>     （5） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
<p>     （6） 所涉及的个人信息是个人信息主体自行向社会公众公开的；</p>
<p>      （7） 根据个人信息主体要求签订和履行合同所必需的；</p>
<p>     （8） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
<p>     （9） 维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；</p>

<p>     （10） 个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的。</p>

<p>     4. 匿名化处理</p>

<p>      对于我们收集到的您的信息，我们将通过技术手段及时进行匿名化处理。我们会将儿童匿名化后的数据与可用于恢复识别个人的信息分开存储，并保证在后续的儿童个人信息处理中不重新识别个人。匿名化后的信息将无法识别特定儿童的身份，在不泄露儿童个人信息的前提下，我们有权对匿名化处理后的用户数据进行分析、挖掘和利用，有权对飞天产品的使用情况进行统计分析并用于可能的第三方信息共享。</p>

<p>三、我们如何使用 Cookie 和同类技术  </p>

<p>      我们或我们的第三方合作伙伴，可能通过cookies和web beacon收集和使用您的信息，并将该等信息储存为日志信息。我们使用自己的cookies和web beacon，目的是为您提供更个性化的用户体验和服务，并用于以下用途：</p>

<p>     1. 记住您的身份。例如：cookies和web beacon有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息</p>

<p>      2. 分析您使用我们服务的情况。例如，我们可利用cookies和web beacon来了解您使用我们的服务进行什么活动，或哪些服务最受您的欢迎。我们为上述目的使用cookies和web beacon的同时，可能将通过cookies和web beacon收集的非个人身份信息，经统计加工后提供给其他合作伙伴，用于分析用户如何使用我们的服务。     我们的产品和服务上可能会有其他合作方放置的cookies和web beacon。这些cookies和web beacon可能会收集与您相关的非个人身份信息，以用于分析您如何使用该等产品或服务。这些第三方cookies和web beacon收集和使用该等信息，不受本隐私政策约束，而是受相关第三方的隐私政策约束，我们不对此类cookies和web beacon承担责任。     您可以通过浏览器设置拒绝或管理cookies。但请注意，如果停用cookies或web beacon，您有可能无法享受最佳的服务体验，某些产品或服务可能也无法正常使用。  </p>

<p>四、我们如何共享、转让、公开披露儿童的个人信息</p>

<p>     1. 我们会以高度的勤勉义务对待您的信息，除非获得您的监护人的事先明确同意，我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，但经过处理无法识别特定个人且不能复原的除外。目前，我们会在以下情形中，向您的监护人征求您的监护人对共享您个人信息的授权同意：</p>

<p>     （1） 将您的设备信息共享至北京锐讯灵通科技有限公司的友盟SDK用于发送消息（包括运营活动、版本更新等）；</p>

<p>     （2） 将您的IP地址、浏览器类型、使用语言、访问日期和时间、软硬件特征信息及您需求的网页记录、设备型号、设备识别码、操作系统、分辨率、电信运营商共享至北京锐讯灵通科技有限公司的友盟SDK用于数据统计，以便提供更好的服务体验；</p>

<p>     （3） 将您的Crash环境：Crash信息及线程堆栈，ROM/RAM/SD卡容量、网络/语言等状态App信息：包名、版本、所属进程名，设备信息：IMEI等设备识别分享至深圳市腾讯计算机系统有限公司的BuglySDK，用于判断Crash设备统计；</p>

<p>     （4） 将您的手机状态、网络状态、读取写入外部储存共享至支付宝(中国)网络技术有限公司的支付宝SDK，用于处理和缓存您的支付订单及支付状态；</p>

<p>     （5）您可以将您感兴趣的内容利用飞天app中的分享功能分享到微信、朋友圈、QQ、QQ空间、新浪微博等，建议您在分享前了解第三方服务的隐私政策，便于您审慎决策。我们仅会出于合法、正当、必要、特定、明确的目的共享儿童个人信息。对我们与之共享个人信息的公司、组织和个人，我们会进行安全评估，并与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理信息。</p>
<p>     2. 我们不会非法出售、非法向他人提供您的信息；我们不会将您的该等信息提供给任何第三方，也不会用于任何其他目的和用途，但是以下情形除外：</p>
<p>     （1） 经您的监护人事先同意，向第三方披露；</p>

<p>     （2） 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>

<p>     （3） 在我们提供服务时，可能将您的信息用于身份验证、客户服务、安全防范、诈骗监测、存档和备份等用途，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、其他用户、我们的关联公司、合作伙伴及其他受信任的第三方供应商、服务商、代理商及社会公众的合法权益，以确保我们向您提供的产品和服务的安全性；</p>

<p>     （4） 我们与第三方合作向您提供飞天平台的相关服务或其他网络服务，且该等第三方同意承担与我们同等的保护用户隐私的责任；</p>

<p>     （5） 其他根据法律、法规或者政策应进行的披露。</p>

<p>     3. 我们仅会在以下情形下，公开披露您的个人信息：</p>

<p>     （1） 获得您的监护人的明确同意后；</p>

<p>     （2） 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>

<p>     4. 共享、转让、公开披露个人信息时事先征得授权同意的例外：</p>

<p>     （1） 与个人信息控制者履行法律法规规定的义务相关的；</p>

<p>     （2） 与国家安全、国防安全直接相关的；</p>

<p>     （3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>

<p>     （4） 与刑事侦查、起诉、审判和判决执行等直接相关的；</p>

<p>     （5） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>

<p>     （6） 个人信息主体自行向社会公众公开的个人信息；</p>

<p>     （7） 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>

<p>     5. 根据法律规定，共享、转让、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理无需另行向您的监护人通知并征得您的监护人的同意。</p>

<p>     五、监护人和儿童如何管理自己的信息及账号注销</p>

<p>     按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>

<p>     1. 查询权 您有权查询您的个人信息，法律法规规定的情形例外除外。 您可以通过“飞天-我的-头像-编辑资料”查询或编辑您的账户中的个人资料等信息。</p>

<p>     2. 更正权 当您发现我们处理关于您的个人信息有错误时，您可以通过查询路径进行信息修改。</p>

<p>     3. 删除权 以下情形下，您可以向我们提出删除个人信息的请求：</p>

<p>     （1） 如果我们处理个人信息的行为违反法律法规；</p>

<p>     （2） 如果我们收集、使用您的个人信息，却未征得您的同意；</p>

<p>     （3） 如果我们处理个人信息的行为违反了与您的约定；</p>

<p>     （4） 如果您不再使用我们的产品或服务，或您注销了账号；</p>

<p>     （5） 如果我们不再为您提供产品或服务。</p>

<p>      4. 撤销权</p>

<p>     （1） 对于您个人主动提供的信息，您可以在您的个人账户“飞天-头像-个人资料”中进行撤销您的个人信息；</p>

<p>     （2） 对于您授权给我们的信息，您可以在“飞天-设置-隐私-权限管理”中进行撤销。请您注意，不同的设备中，路径可能有所不同，具体参考您的设备及系统开发方的说明和指引。</p>

<p>     5. 注销权 如果您决定停止使用飞天的，则您可以对您的账号进行注销。账号注销可以在 “飞天官网-关于我们-联系客服-账号注销”中实现，您也可以在“飞天-客服中心”通过24小时在线客服协助下进行注销。</p>

<p>     注销账号之后此账号内的数据无法恢复，包括但不限于账户内的资产信息如红豆、红币、优惠券等；账号信息内容也将全部清除，包含但不限于该账号发布过的文章、评论等。该注销操作不能撤销，请您务必谨慎申请。</p>

<p>     6. 在以下情形中，按照法律法规的要求，我们将无法响应您的上述请求：</p>

<p>     （1） 与个人信息控制者履行法律法规规定的义务相关的；</p>

<p>     （2） 与国家安全、国防安全直接相关的；</p>

<p>     （3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>

<p>     （4） 与刑事侦查、起诉、审判和执行判决等直接相关的；</p>

<p>     （5） 个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>

<p>     （6） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>

<p>     （7） 响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>

<p>     （8） 涉及商业秘密的。</p>

<p>六、我们如何存储儿童的个人信息  </p>

<p>     我们在中华人民共和国境内运营中收集和产生的个人信息，原则上存储在中国境内，并按照中国中华人民共和国法律法规、部门规章等规定的存储期限存储您的个人信息，存储期限为您使用飞天的期限或者向飞天发出删除您的个人信息之前，当您要求删除该存储信息或者决定停止使用飞天注销账号之时，我们将及时删除您的个人信息。    </p>

<p>七、我们如何保护儿童的个人信息  </p>

<p>     1. 我们非常重视您的个人信息安全。我们成立了专门的安全团队，努力采取各种合理的物理、电子和管理方面等一切合理可行的安全措施来保护您的个人信息，并尽最大合理努力使您的个人信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们以最小授权为原则，仅允许有必要知晓您的信息的员工访问您的个人信息，我们对可能接触到您个人信息的员工也采取了严格管理，包括但不限于采取信息访问权限控制、与接触个人信息的人员签署保密协议、监控该等人员的操作情况等措施。</p>

<p>     2. 尽管有上述第1款的约定，但是请您理解并明白，由于存在各种各样无法预知或现有技术无法防御的恶意手段，即便我们已经尽力采取必要的保护措施，您的信息仍有可能被泄露、毁损或灭失。如您发现您的账号、密码因任何原因已经或者将要泄露时，您应当立即跟我们联系，以便我们可以及时采取应对措施防止或减少您的相关损失。</p>

<p>     3. 如果我们发生企业合并、分立等情形的，则您的信息将由届时存续的企业主体继续履行保护义务，并由我们以公告、短信或其他书面方式通知您；如果我们的产品、服务停止运营的，则我们将及时停止继续收集您的信息，并以公告、短信等方式通知您，届时我们将对您的信息进行及时删除处理。</p>

<p>     4. 若不幸发生儿童个人信息安全事件，我们会启动应急预案，阻止安全事件扩大，并及时告知监护人和儿童：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、监护人和儿童可自主防范或降低风险的建议、对监护人和儿童的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知监护人和儿童，难以逐一告知儿童个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报儿童个人信息安全事件的处置情况。    </p>

<p>八、本政策的修订      </p>

<p>     我们可不定期修改本隐私政策，变更后的隐私政策将在修订生效前通过飞天平台公告或以其他适当方式通知您。该等情况下，若您继续使用我们的服务，即表示同意受经修订的隐私政策的约束</p>

<p>九、争议管辖      </p>

<p>     1. 本隐私政策的签订、生效、履行、争议的解决均适用中华人民共和国法律</p>

<p>     2. 有关本协议的争议应通过友好协商解决，如果协商不成，该争议将提交公司所在地有管辖权的法院诉讼解决。</p>


  </div>
</template>

<script>
export default {

}
</script>

<style>
    .agreement{
        margin: 100px 10px 0 10px;
        font-size: 20px;
        line-height: 30px;
    }
    .agreement h2{
        text-align: center;
    }
</style>