<template>
  <div class="help" style="margin-top: 80px;">
    <page-6/>  <!-- 展示页6 疑问答疑 -->
  </div>
</template>

<script>

const Page6 = ()=> import('./page6/Page6.vue');
  
  export default {
    name : "Help",
    components: {
      Page6
    },
  }
</script>