import { request } from '../request';


/**
  * @description 首次请求数据
 */
function getFirstData() {
  return request("ipsw/web/list").then((res) => {
    self.$store.commit('pushData', [res.data.data.name, res.data.data.type, res.data.data.version]);
    self.$store.commit('pushIpswlist', res.data.data.rows);
  });
}

function getFromData(params) {

  //对象 转 参数字符串

  let a = '';
  for (const key in params) {//拼接
    a += key + '=' + params[key] + '&'
  }
  a = a.substr(0, a.length - 1); //删除最后一个 &
  while(a.indexOf(' ') >= 1){//替换空格 -> +
    a = a.replace(' ','+');
  }

  return request({
    url: `ipsw/web/list?${a}`,
    method: 'get',

  }).then(res => {
    self.$store.commit('pushData', [res.data.data.name, res.data.data.type, res.data.data.version]);
    self.$store.commit('pushIpswlist', res.data.data.rows);
  });
}

export { getFirstData, getFromData }