<template>
  <div class="agreement">
    <h2>飞天云手机平台免责声明</h2>
 <p>飞天云手机平台（以下称“本软件”）是以创建移动智能手机健康生态应用模式为理念，以用户需求为中心，通过先进的无线互联网技术、数据处理技术、虚拟云技术等，为全国乃至全球广大智能手机用户群体提供各种手机应用软件展示、下载、应用与管理等多元化、全方位的虚拟手机平台。本软件前期主要为用户提供手机游戏相关应用与服务，支持用户实现手机游戏的24小时离线挂机应用，在此基础上，后期将逐步拓展到整个手机应用软件的支持，打造全平台、全方位支持的“飞天云手机平台”，为国的智能手机用户提供手机软件的虚拟化应用和服务。</p>
 
 <p>用户在使用本软件前，请务必认真阅读本免责声明中的各项条款，本声明适用于本软件。通过本免责声明，飞天云手机平台团队（以下称飞天团队）对用户使用本软件可能带来的风险及对用户手机系统的影响进行提示，如用户在阅读本声明后不同意此声明中的任何条款或者存在任何疑虑，请立刻停止使用本软件；如果选择接受并继续使用本软件，则视为用户同意并认可本声明的所有条款。</p>

 <p>1.总则：本软件可以随时执行并全权决定更改“条款”，如“条款”有任何变更，经修订的条款一经公布，立即自动生效。</p>

 <p>2.用户确认知悉本软件所有功能及为实现本软件各功能所进行的必要操作，用户可根据自身需求自愿选择使用本软件及相关服务。如有因错误使用本软件造成的任何损失，本软件及飞天团队不承担任何责任。</p>

 <p>3.本软件可能使用或包含了第三方软件或服务，这些服务是应用户要求，以为用户提供便利为目的而设置，用户在使用这些服务前，应另行与该第三方达成服务协议，支付相应费用并承担可能的风险。本软件及飞天团队并无权在第三方软件或服务协议中授予用户使用这些服务的任何权利，也无权对该等服务提供任何形式的保证。</p>

 <p>4.因使用第三方软件或服务，所带来的风险和一切后果，如：账号盗号、封号等，将完全由用户自己承担，本软件及飞天团队不承担责任。</p>

 <p>5.若用户发布任何违法或者侵犯第三方合法权益的信息，由用户自行承担责任。</p>

 <p>6.用户不得以商业盈利或游戏作弊为目的使用本软件，也不得使用本软件去篡改其他任何第三方软件，若由此产生一系列错误、故障、问题等，本软件及飞天团队不承担责任。</p>

 <p>7.用户在使用本软件过程中所提供的个人资料，本软件除用户本人同意外不会将用户的任何资料以任何方式泄露给第三方。当政府部门、司法机关等依照法定程序要求飞天团队披露用户个人资料时，本软件将根据执法单位的要求提供用户个人资料，在此情况下的信息披露，本软件及飞天团队不承担任何责任。</p>

 <p>8.飞天团队不会以任何形式、任何方法获取用户在本软件上的游戏及其他应用软件的账号及相关资料，如出现由于账号信息泄露导致的任何账号风险和损失，本软件及飞天团队不承担任何风险、责任及赔偿。</p>

 <p>9.本软件经过详细的测试，但不能保证与所有的软硬件系统完全兼容，不能保证本软件完全没有错误。如果出现不兼容及软件错误的情况，用户可通过各反馈途径将情况告知飞天团队，获得技术支持。如果无法解决兼容性问题，用户可以删除本软件。</p>

 <p>10.本软件的功能及启动的服务受制于服务器、通讯网络的稳定性等相关因素，对于因电信系统或互联网网络故障、设备故障、病毒或黑客入侵、信息损坏或丢失、设备系统问题或其它任何非本软件可控因素而产生的损失，本软件及飞天团队不承担任何责任。</p>

 <p>11.在适用法律允许的最大范围内，对因使用或不能使用本软件所产生的损害及风险，包括但不限于直接或间接的个人损害、商业赢利的丧失、贸易中断、商业信息的丢失或任何其它经济损失，本软件及飞天团队不承担任何责任。</p>

 <p>12.请用户在下载及安装使用本软件前，必须详细阅读本声明及“飞天云手机平台用户使用许可协议”，并予以遵守，用户违反“使用许可协议”条款，飞天团队有权采取包括但不限于中断使用许可、停止提供服务、限制使用、法律追究等措施。</p>


 <p>本声明所定的任何条款的部分或全部无效者，不影响其它条款的效力。</p>
 <p>本软件和飞天团队在法律允许最大范围内对本声明拥有最终解释权与修改权。</p>
 

  </div>
</template>

<script>
export default {

}
</script>

<style>
    .agreement{
        margin: 100px 10px 0 10px;
        font-size: 20px;
        line-height: 30px;
    }
    .agreement h2{
        text-align: center;
    }
</style>