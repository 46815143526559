<template>
  <div class="footer">
    <div class="main">
      <div class="main-qrcode">
        <img src="@/assets/img/i_26.png">
      </div>
      <div class="main-infomation">
        <div class="main-infomation-web">官网：www.ftyunos.com</div>
        <div class="main-infomation-copying">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
            <img src="@/assets/img/ghs.png" class="police">
            闽ICP备2023026834号
          </a>
        </div>
        <div class="main-infomation-address">QQ用户群：421802605</div>
       
        <!-- <div class="main-infomation-wechat">问题咨询：微信公众号搜索「沙漏验机」</div> -->
      </div>
    </div>
    
    <!-- <div class="copyright">
      <span>© 2016.2 - 2020</span>
      <a href="javascript:;">沙漏验机</a>
      <span>版权所有. </span>
      <a href="http://beian.miit.gov.cn/">粤ICP备17057930号-1</a>
    </div> -->
  </div>
</template>

<script>
  export default {
    name : "Footer"
  }
</script>

<style scoped>
  .footer{
    width: 100%;
    height: 300px;
    background-color: #000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: bisque;
  }

  .main{
    width: 1200px;
    height: 200px;
    display: flex;

    align-items: center;
    justify-content: space-around;
  }

  .main-infomation-web{
    color: #D9D9DA;
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  .main-infomation-title{
    color: #D9D9DA;
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  .main-infomation-address , .main-infomation-wechat{
    color: #D9D9DA;
    margin: 10px 0 ;
    font-size: 1rem;
  }

  .main-infomation-copying{
    color: #333333;
    margin: 10px 0 ;
    font-size: 1rem;
  }

  .main-infomation-copying a{
    color: #D9D9DA;
    text-decoration: none;
  }

  

  .copyright a{
    text-decoration: none;
    color: #606266;
    margin: 0 10px;
    font-size: .7rem;
  }

  .copyright a:hover{
    color: #409EFF;
  }

  .copyright span{
    color: #8A8A8A;
    font-size: .7rem;
  }
</style>