<template>
  <div class="ipsw">
    <div class="title">固件下载</div>
    <dev/> <!-- 设备筛选 -->
    <ipsw-list/>
  </div>
</template>

<script>

  import IpswList from './Item/IpswList.vue';
  import Dev from './Item/Dev.vue';
  
 
  export default {
    name : "Ipsw",
     components: {
      Dev,
      IpswList
    },
  }
</script>

<style scoped>
 .ipsw{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
 }

 .title{
   font-size: 2.2rem;
   text-align: center;
   padding: 100px;
   letter-spacing: 1px;
 }
</style>