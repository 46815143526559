<template>
  <div class="page-2">
    <div class="v1">提供各类免费手游辅助</div>
    <div class="v2">部落冲突 / 天龙八部 / 新剑侠情缘 / 热血传奇</div>
    <div class="v3">
      <img src="@/assets/img/i_27.png">
    </div>
  </div>
</template>

<script>
  export default {
    name : "Page2"
  }
</script>

<style>
  .page-2{
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  .v1{
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 40px;
  }

  .v2{
    font-size: 2rem;
    color: #555555;
    letter-spacing: 5px;
    margin-bottom: 40px;
  }


</style>