<template>
  <div class="cooperate" style="margin-top: 80px">
    <page-1 />  <!-- 沙漏验机 商务合作！ -->
    <!-- <page-2 />  产品介绍 -->
    <!-- <page-3 />  公司介绍 -->
    <!-- <page-4 />  联系我们 -->
    <!-- <page-5 />  我们能为企业及个体或... -->
  </div>
</template>

<script>
import Page1 from "./page1/Page1.vue";
// import Page2 from "./page2/Page2.vue";
// import Page3 from "./page3/Page3.vue";
// import Page4 from "./page4/Page4.vue";
// import Page5 from "./page5/Page5.vue";

export default {
  name: "Cooperate",
  components: {
    Page1,
    // Page2,
    // Page3,
    // Page4,
    // Page5,
  },
};
</script>