<template>
  <div id="app">
    <navbar/>
    <router-view></router-view>
    <x-footer/>
  </div>
</template>

<script>
  import Navbar from './components/navbar/Navbar';
  import xFooter from '@/components/footer/Footer.vue';
  
  export default {
    name:"App",
    components : {
        Navbar,
        xFooter
    }
  }
</script>


<style>
  /* 全局CSS导入 */
  @import '~@/assets/css/normalize.css';
  @import '~@/assets/css/base.css';
</style>
