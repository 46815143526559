<template>
  <div class="sha-lou-app" style="margin-top: 80px;">
    <page-1/>
    <!-- <page-2/> -->
    <!-- <page-3/> -->
  </div>
</template>

<script>
  import Page1 from './page1/Page1.vue';
  // import Page2 from './page2/Page2.vue';
  // import Page3 from './page3/Page3.vue';
  const xFooter = ()=> import('@/components/footer/Footer.vue');

  export default {
    name : "ShalouApp",
    components : {
      Page1,
      // Page2,
      // Page3
    }
  }
</script>