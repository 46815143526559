<template>
  <div class="page-1">
    <div class="left">
      <img src="~@/assets/img/shalouapp/1.png">
    </div>

    <div class="right">
      <div class="right-1">飞天云APP已上线！</div>
      <div class="right-2">功能强大、服务新颖的飞天云手机APP已上线，加入我们的用户群，实时获取最新进度！</div>
      <div class="right-3"><img src="~@/assets/img/shalouapp/2.png"></div>
      <div class="right-4">扫码加入用户群！</div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'Page1'
}
</script>

<style scoped>
  .page-1{
    display: flex;
    width: 100%;
    height: 800px;
    background: linear-gradient(90deg,#6a80eb,#fc466b)!important;
    justify-content: center;
    align-items: center;
  }

  .left img{
    width: 300px;
    height: 600px;
  }

  .left{
    margin: 100px 80px;
  }

  .right img{
    width: 180px;
    height: 180px;
  }

  .right-1{
    color: #FFF;
    font-size: 3rem;
  }

  .right-2 , .right-4 {
    color: #ddd;
    font-size: 1.8rem;
    letter-spacing: 1px;
    margin: 30px 0;
  }
</style>