<template>
  <div class="navbar" :class="{ bgc1: this.isBgc1, bgc2: this.isBgc2, bgc3: this.isBgc3 }">
    <div class="navbar-logo">
      <img src="../../assets/img/logo.png" alt="" />
      <!-- <h2>飞天云手机</h2> -->
    </div>

    <div class="navbar-item">
      <ul>
        <li v-for="(item, index) in items">
          <a
            href=""
            @click="itemOnClick(index)"
            :class="{ active: index === cIndex }"
            >{{ item.text }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      items: [
        { route: "/home", text: "飞天云手机" },
        { route: "/shalouapp", text: "扫码下载APP" },
        // { route: "/ipsw", text: "固件下载" },
        { route: "/help", text: "帮助中心" },
        { route: "/course", text: "福利中心" },
        { route: "/cooperate", text: "关于我们" },
      ],
      cIndex: 0,
      isBgc1: true,
      isBgc2: false,
    };
  },
  methods: {
    itemOnClick(index) {
      event.preventDefault(); //阻止默认事件

      // 路由跳转
      if (this.$route.path != this.items[index].route) {
        this.$router.push(this.items[index].route);
        this.cIndex = index;
        this.changBgc();
      }
    },

    // 修改背景颜色
    changBgc() {
      if (this.$route.path == "/home" || this.$route.path == "/shalouapp") {
        this.isBgc1 = true;
        this.isBgc2 = false;
        this.isBgc3 = false;
      }

      if (this.$route.path == "/ipsw" || this.$route.path == "/help") {
        this.isBgc1 = false;
        this.isBgc2 = true;
        this.isBgc3 = false;
      }

      if (this.$route.path == "/cooperate") {
        this.isBgc1 = false;
        this.isBgc2 = false;
        this.isBgc3 = true;
      }
    },
  },
  created() {
    /**
     * 刷新时 同步 cIndex 、 背景颜色
     *
     */
    this.cIndex = this.items.findIndex(
      (item) => item.route === this.$route.path
    );
    this.changBgc();
  },
};
</script>

<style scoped>
.navbar {
  height: 80px;
  width: 100%;
  display: flex;

  z-index: 10;

  /* 吸顶 */
  position: fixed;
  top: 0;
}

.bgc1 {
  background: linear-gradient(90deg, rgb(197, 182, 248), #46b9fc);
}

.bgc2 {
  background: #257dff;
}

.bgc3 {
  background: linear-gradient(90deg, #a75fe4, #7327ec);
}

.navbar-logo {
  display: flex;
  align-items: center;
  flex: 1;
}

.navbar-logo img {
  height: 70px;
  margin-left: 36px;
  margin-right: 8px;
  margin-bottom: 3px;
}

.navbar-logo h2 {
  color: #fff;
  line-height: 80px;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.navbar-item {
  height: 80px;
  flex: 3;
}

.navbar-item ul {
  list-style: none;
}

.navbar-item li {
  height: 80px;
  line-height: 80px;
  width: 108px;
  float: left;
  margin: 0 15px;
  text-align: center;
}

.navbar-item a {
  text-decoration: none;
  color: #fff;
  width: 100%;
  height: 100%;
  display: block;
}

.active {
  border-top: 2px solid #eee;
  font-weight: 600;
}
</style>