import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

/**  问题：
 *  elemetUI 不可以使用异步导入
 *  会导致  NavMenu(导航栏) activeIndex 无法绑定。
 */
import Home from '@/views/home/Home.vue';
import ShalouApp from '@/views/shalouapp/ShalouApp.vue';
import Ipsw from '@/views/ipsw/Ipsw.vue' ;
import Help from '@/views/help/Help.vue';
import Course from '@/views/course/Course.vue';
import Cooperate from '@/views/cooperate/Cooperate.vue';
import VIPAgreement from '@/views/policy/VIPAgreement.vue';
import PhoneAuthAgreement from '@/views/policy/PhoneAuthAgreement.vue';
import PrivacyAgreement from '@/views/policy/PrivacyAgreement.vue';
import PrivacyChildrenAgreement from '@/views/policy/PrivacyChildrenAgreement.vue';
import UserAgreement from '@/views/policy/UserAgreement.vue';
import DisclaimerAgreement from '@/views/policy/DisclaimerAgreement.vue';
import APPSDK from '@/views/policy/APPSDK.vue';


const routes = [
  {path: '/',redirect : '/home'},
  {path : '/home' , component : Home},
  {path : '/shalouapp' , component : ShalouApp},
  {path : '/ipsw' , component : Ipsw},
  {path : '/help' , component : Help},
  {path : '/course' , component : Course},
  {path : '/cooperate' , component : Cooperate},
  {path : '/VIPAgreement' , component : VIPAgreement},
  {path : '/PhoneAuthAgreement' , component : PhoneAuthAgreement},
  {path : '/PrivacyAgreement' , component : PrivacyAgreement},
  {path : '/PrivacyChildrenAgreement' , component : PrivacyChildrenAgreement},
  {path : '/UserAgreement' , component : UserAgreement},
  {path : '/DisclaimerAgreement' , component : DisclaimerAgreement},
  {path : '/APPSDK' , component : APPSDK},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
