import axios from 'axios'

export function request(config){
  // create an axios instance
  const service = axios.create({
    baseURL: '/rocket-download/' // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    // timeout: 5000 // request timeout
  })

  // request interceptor
  service.interceptors.request.use(
    config => {
      config.headers['Access-Control-Allow-Origin'] = '*'
      return config
    },
    error => {
      console.log(error) // for debug
      return Promise.reject(error)
    }
  )

  // response interceptor
  service.interceptors.response.use(
    response => {
      const res = response.data
      return res
    },
    error => {
      return Promise.reject(error)
    }
  )
  return service(config);
}
