import axios from 'axios';

export function request(config){
  const foo = axios.create({
    baseURL : 'http://tongtianzhushou.com:6837/rocket-download/',
    timeout : 5000,
  });
  
  return foo(config);
}
