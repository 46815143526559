<template>
  <div class="home" style="margin-top: 80px;">
    <swpier/>  <!-- 轮播图 -->
    <page-2/>  <!-- 展示页2 兼容所有IOS设备和系统 -->
    <!-- <page-3/>  展示页3 沙漏验机软件优势 -->
    <!-- <page-4/>  展示页4 沙漏验机支持检测的项目 -->
    <!-- <page-5/>  展示页5 下载页 -->
  </div>
</template>

<script>
  import Swpier from "./swiper/Swpier.vue";
  import Page2 from './page2/Page2.vue';

  //异步载入
  // const Page3 = ()=> import('./page3/Page3.vue');
  const Page4 = ()=> import('./page4/Page4.vue');
  // const Page5 = ()=> import('./page5/Page5.vue');

 

  export default {
    name: "Home",
    components: {
      Swpier,
      Page2,
      // Page3,
      Page4,
      // Page5,
    },
  };
</script>


