<template>
  <div class="agreement">
    <h2>飞天云手机授权协议</h2>
  <p>  韵帆数字（厦门）科技有限公司（以下称 “韵帆公司”）在此特别提醒用户，在使用飞天云手机授权功能（以下称 “本功能”）前请认真阅读、充分理解本协议中各条款，包括但不限于免除或者限制责任的条款。韵帆公司通过该使服务协议，对您使用本功能的方式及可能包含的风险进行提示，请用户审阅并选择接受或不接受本协议（未成年人应在法定监护人陪同下审阅）。 如您不同意本服务协议及/或随时对其的修改，您可以主动停止使用本功能；您一旦使用本功能提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括韵帆公司对服务协议随时所做的任何修改。您的使用行为将被视为对本协议全部内容的认可，并同意接受本协议各项条款的约束。</p>
  <p> 一、功能说明</p>
  <p>   （授权功能：用户（下称“授权用户”）可在飞天云手机平台（包括但不限于电脑端、移动应用端、H5页面等）内可通过本功能将其购买的云手机授权其他用户（下称“被授权用户”）可进行远程浏览、使用、控制等。</p>
  <p>   1、若您为授权用户</p>
  <p>   1.1 您可以通过以下方式进行授权：</p>
  <p>   授权码：授权用户通过本功能生成授权码，并将授权码发送给被授权用户用于授权激活。</p>
  <p>   授权指定用户：授权用户可以在本功能输入被授权用户的手机号码，直接激活授权。</p>
  <p>   1.2 您可以在授权时选择是否授予被授权用户对被授权云手机的远程控制权限：若您确认授予该控制权限的，授权期间，您与被授权用户可互相远程浏览、使用、控制被授权的云手机界面；若您选择不授予该控制权限的，被授权用户仅可浏览被授权的云手机界面，无法进行除浏览外的任何操作。</p>
  <p>   1.3 您可以随时取消授权，收回授予被授权用户的云手机权限。</p>
  <p>   1.4 您可授权予其他单个用户的最大授权时间为72小时，自授权码生成或您输入指定用户手机号时起算。</p>
  <p>   2、若您为被授权用户</p>
  <p>   2.1您可以进行授权激活</p>
  <p>   授权码：通过本功能输入授权用户生成并发送给您的授权码，免费激活被授权的云手机；</p>
  <p>   授权指定用户：授权用户通过本功能输入您的手机号后直接激活授权，您无需进行任何激活操作。</p>
  <p>   2.2 您被授权的云手机使用时间由授权用户决定。</p>
  <p>   2.3 您最多可通过本功能同时被免费授权远程浏览、使用、控制20台云手机。</p>
  <p>   2.4 授权用户可实时远程浏览、使用、控制已授权予您的云手机，并可以随时撤回云手机授权。</p>
  <p>   2.5 您通过本功能被授权远程浏览、使用、控制的云手机不可再转授权给其他用户使用。</p>
  <p> 二、风险提示</p>
  <p>   1、若您为授权用户，当您授予被授权用户控制云手机的权限时，被授权用户可实时控制您的云手机，同时可随时查看您云手机内的个人信息（包括但不限于您的电话号码、游戏账号、微信、QQ等云手机已安装应用程序内所有信息、记录）。该情形下您的个人信息安全由您本人负责，请知晓并妥善保管自己的账号及其他重要信息，谨慎使用授权功能。</p>
  <p>   2、若您为被授权用户，授权用户可实时控制您的云手机，同时可随时查看您使用被授权的云手机时留下的个人信息（包括但不限于电话号码、游戏账号、微信、QQ等云手机中已安装应用程序内所有信息、记录）。并且，授权用户可随时撤回对云手机的授权，该情形下您的个人信息安全由您本人负责，请知晓并妥善保管自己的账号及其他重要信息，谨慎使用本功能。</p>
  <p>   3、授权过程中存在账号被盗取等相关用户信息安全风险，请谨慎使用，韵帆公司不承担因本功能发生的任何非韵帆公司原因导致的相关信息安全责任。</p>
  <p>   您充分了解并且同意，如您选择使用本功能，视为您充分知悉上述风险，并同意因使用本功能产生的所有风险后果由您个人自行承担。若您使用本功能将您的个人密码泄露给他人，由此导致的任何个人资料泄露，或遭受任何财物的损失、损害，除法律有明确规定外，韵帆公司不承担任何责任。</p>
  <p> 三、其他</p>
  <p>   1、您对飞天云手机授权功能或本协议的任何部分若有意见或建议可通过韵帆公司客户服务部门（邮箱：tttools1797@gmail.com）与韵帆公司联系。</p>
  <p>   2、因本须知或与本须知有关的任何争议，用户可通过上述联系方式与韵帆公司协商解决；如若协商不成，任何一方均可将有关争议提交至韵帆公司所在地人民法院解决。</p>
  <p>   3、您依本协议条款所取得的服务权利不可转让。</p>

  </div>
</template>

<script>
export default {

}
</script>

<style>
    .agreement{
        margin: 100px 10px 0 10px;
        font-size: 20px;
        line-height: 30px;
    }
    .agreement h2{
        text-align: center;
    }
</style>