<template>
  <div class="agreement">
    <h2>飞天云手机平台用户使用协议</h2>
<p>    欢迎进入飞天云手机平台！</p>
<p>飞天云手机平台（包括但不限于以APP形式提供的操作环境、微信公众号、小程序、网站等）为韵帆数字(厦门)科技有限公司负责运营的依托于移动内容的云计算平台。飞天云手机平台用户使用许可协议（以下简称“本协议”）由您和韵帆数字(厦门)科技有限公司（以下称 “韵帆公司”或“我方”）共同认可签署,本协议内容包括条款正文、 <a href="https://www.ftyunos.com/PrivacyAgreement" target="_blank">《飞天隐私政策》</a>及韵帆数字(厦门)科技有限公司已经发布的或将来可能发布的各类规则。请您于注册或使用本服务前，仔细阅读并完全理解本服务条款的全部内容，其中用粗字体予以标注的，是与您的权益有关或可能具有重大关系，及对本网站具有或可能具有免责或限制责任的条款。本网站在此特别提醒您，在使用飞天云手机平台（以下称“本软件”）申请注册流程之前，您必须认真阅读、充分理解本协议各条款，特别是其中所涉及到免除或限制本网站责任的条款、排除或限制您权利的条款、授权条款、法律适用和争议条款等。如果您不同意协议任意或全部内容的，请不要以接受本软件服务的形式（包括但不限于点击同意/接受/下一步、进入购买程序、对飞天服务内容进行任何购买、采用任何其他形式的确认操作，下统称：接受服务的形式）进行下一步操作或使用本软件服务。如果您采用接受服务的形式进行下一步操作或使用本服务，即表明您已阅读、理解并同意签署本协议，自愿接受并遵守本协议的全部约定。</p>

<p>您知悉贵我双方共同签署的本协议列明的条款并不能完整罗列并覆盖您与韵帆公司之间的所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。韵帆公司有权随时根据有关法律法规的变化、公司经营状况和经营策略调整和软件升级等原因修订本协议。变更后的协议条款将通过系统后台公告的形式发布，请您务必仔细阅读，当您采用接受服务的形式进行下一步操作或继续使用本服务，即表明您已经接受本协议的全部修改。更新后的本协议自更新版本发布之日起生效。</p>

<p>如果您是未满18周岁的未成年人，请在法定监护人陪同下阅读并确认本协议条款，并特别注意未成年人使用条款。</p>

<p>一、定义</p>

<p>“本软件”指：由韵帆公司开发供您从下载平台下载，并在相应系统手持移动终端和电脑端中安装、使用的软件系统。</p>

<p>本软件是一款集游戏、工具、礼包及其他手机软件应用的云手机平台，包含多种第三方软件和服务应用，本软件不提供内容制作。</p>

<p>二、权利声明</p>

<p>韵帆公司拥有本软件的版权，与本软件相关的所有信息内容均属于韵帆公司的知识产权，包括但不限于：本软件的文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等，韵帆公司享有知识产权，受到相关法律法规的保护。任何未经授权而擅自修改、发行、出租、翻译、分割、反向编译或反向工程本软件程序的全部或部分行为，均属于侵权行为，韵帆公司保留依法追究其法律责任的权利。</p>

<p>1.本软件的名称、标识、UI、信息内容等包括以上标明的各种信息受法律保护，任何人不得复制、模仿；</p>

<p>2.本软件受知识产权保护，任何第三方不得在未经官方同意下擅自修改、发行、出租、翻译、分割、反向编译或反向工程本软件；</p>

<p>3.本软件内的软件、游戏、文字、图片等信息由其他用户上传，您可浏览、下载、存储、使用，但不得从事侵犯知识产权的活动；</p>

<p>4.您在分享游戏、软件、链接地址时，应确认内容是合法的、不侵犯任何第三方知识产权的方可发布；</p>

<p>5.您在浏览下载本软件中其他用户分享的信息时，发现有侵犯其他知识产权的言论，请及时通知韵帆公司。</p>

<p>三、授权范围</p>

<p>韵帆公司授权您非商业性地使用本软件，并为您提供下载、安装、使用、升级、卸载和提供相关数据服务。您可以自主选择下载、安装或卸载以及使用本软件，可以使用韵帆公司提供的相应数据服务，享受韵帆公司的维护升级服务。您可以非商业性地复制和传播本软件。未经韵帆公司书面授权和许可，您不得进行商业性的销售、复制、传播以及其他商业活动。具体授权范围如下：</p>

<p>1.您可以非商业性地下载、安装及使用本软件；</p>

<p>2.您可以非商业性地复制、传播本软件，但必须保证每一份复制、传播的内容都是完整和真实的,包括所有有关本软件产品的软件、电子文档、版权和商标以及本协议。</p>

<p>3.由于软件适配平台、操作系统及终端限制，本软件仅能在支持的系统平台及终端使用，请您在购买服务前仔细确认是否可在您使用的系统平台及终端使用本软件，若您点击购买服务进行支付后，则代表您已完全理解并确定本软件适用您所用的系统平台及终端。</p>

<p>四、软件使用授权</p>

<p>1.软件使用须知：</p>

<p>本软件是韵帆公司提供的一款旨在帮助您创建移动智能手机健康生态应用模式，提供虚拟手机软件应用和服务的云手机平台。在使用前您需认真了解以下内容：</p>

<p>(1)本软件支持手机游戏等手机应用软件的远程控制功能，对于不同型号及操作系统的手机，本软件的使用方法也不相同。</p>

<p>(2)使用本软件最佳前提保证手机电池电量高于20%，手机存储剩余空间大于10MB，以保证有足够电量及足够的空间完成获取过程。</p>

<p>(3)使用本软件需注册软件账号，即成为注册用户后方可使用本软件，注册用户如果违反本平台相关协议约定，韵帆公司有权暂停或终止用户的账号使用权。</p>

<p>(4)使用本软件在线查看接收到的文字、图片、文件等信息时，您手机会产生相应的数据流量费，由运营商收取。</p>

<p>(5)本软件仅适用于其官方网站公布支持的操作系统，如果您在安装本软件后因任何原因欲放弃使用，可使用各操作系统提供的删除方式删除本软件。</p>

<p>(6)您在本软件上使用第三方产品或应用时，不可使用影响本软件系统的正常运行的产品，如双开、多开、沙盒、框架等软件。</p>

<p>(7)本软件中的授权功能仅用于您在云手机使用过程中发生异常情况，需要通过授权功能授权第三方帮您进行协同工作解决异常问题时使用授权功能，且由于授权第三方操作可能会导致您的隐私信息被第三方泄露，请您谨慎使用该功能；如果因为授权功能引发您与第三方之间的纠纷，飞天概不负责；同时飞天严禁用户使用授权功能进行云手机的线下交易行为。</p>

<p>(8)若您有以下行为之一的，飞天将直接认定您的行为是以商业目的利用授权功能进行线下交易，飞天有权对您的账号进行暂时或永久限制部分或全部功能，您可通过申诉途径提交账号异常需要第三方协助的资料向飞天申请解封。</p>

<p>a.以营利为目的，利用平台授权功能，在第三方电商及社交平台发布云手机出租或转让等信息。</p>

<p>b.月周期内多次将其飞天账户下的部分或全部云手机通过授权功能授权给任意第三方用户使用。</p>

<p>2.软件使用规则：</p>

<p>您应在遵守法律及本协议的前提下使用本软件，在使用本软件时您不得违反以下规定：</p>

<p>(1)您不得使用本软件制作、复制、发布、传播、储存包括但不限于下列内容的信息：</p>

<p>反对宪法所确定的基本原则的；</p>
<p>危害国家安全、泄露国家机密、国家政权、破坏国家统一的；</p>
<p>损害国家荣誉和利益的；</p>
<p>煽动民族仇恨、民族歧视，破坏民族团结的；</p>
<p>破坏国家宗教政策、宣扬邪教和封建迷信的；</p>
<p>散布谣言、扰乱社会秩序、破坏社会稳定的；</p>
<p>观看、参与或散布淫秽、色情、暴力、赌博、恐怖或教唆犯罪的；</p>
<p>侮辱或者诽谤他人，侵害他人合法权利的；</p>
<p>任何侵犯任何第三方作品的知识产权的；</p>
<p>侵害第三方姓名权、名誉权、肖像权、隐私权等各类合法权益的；</p>
<p>含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感内容的；</p>

<p>含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其它内容的；</p>

<p>(2)不得运用本软件进行包括但不限于以下范围的行为：</p>
<p>故意避开或者破坏著作者、开发者为保护本软件著作权而采取的技术措施；</p>
<p>利用本软件误导、欺骗他人；</p>
<p>违反国家规定，对本软件系统功能进行删除、修改、增加、干扰，造成本软件不能正常运行；</p>
<p>破坏本软件系统的正常运行，故意传播计算机病毒等破坏性程序；</p>
<p>其他任何危害计算机信息网络安全的；</p>
<p>3.账号责任</p>

<p>（1）您对本软件的账号负责，该账号仅您本人可以使用，未经微算飞天云手机书面同意并作账号实名变更，该账号不可以营利为目的许可第三方使用、不可转让、不可赠与、不可继承，否则飞天云手机有收回或暂时/永久封禁该账号的权利；</p>

<p>（2）您对密码及账户的机密性承担全部责任，并对账户中发生的所有活动承担全部责任，因密码泄露或用户名遗忘所致的损失，由您自行承担。若您发现他人冒用或盗用其账号及密码或其他未经合法授权的使用或任何其他不安全的行为，您应立即以有效方式通知飞天云手机，要求飞天云手机暂停相关服务。同时，您理解飞天云手机对您的请求采取行动需要合理期限，在此之前，飞天云手机对已执行的指令及所导致您的损失不承担任何责任；</p>

<p>（3）您理解并同意，飞天云手机有权了解您使用本软件服务的真实背景及目的，有权要求您如实提供真实、全面、准确的信息，如果飞天云手机有合理理由怀疑您提供的信息不真实、进行无授权线下交易、虚假交易，或您的行为违反飞天云手机的服务规则的，飞天云手机有权暂时或永久限制您账户下所使用服务的部分或全部功能。</p>

<p>4.对于从非韵帆公司指定站点或非韵帆公司自主发行的渠道下载的本软件产品，韵帆公司无法保证该软件是否感染计算机病毒、是否隐藏有伪装的木马程序或者黑客软件，使用此类软件，将可能导致不可预损失测的风险，建议您不要轻易下载、安装、使用，如若发生以上情况，韵帆公司不承担任何由此产生的损失和赔偿。</p>

<p>5.本软件不含有任何旨在破坏您移动通讯设备数据和获取您隐私信息的恶意代码，不含有任何跟踪、监视您的功能代码，不会监控您网上、网下的行为，不会收集您使用其它软件、文档等个人信息，不会泄漏您隐私。</p>

<p>6.如若您未遵守本软件使用规则，飞天有权采取相应措施，包括但不限于对本软件帐号和云手机的冻结、封停、终止部分或全部本服务以及删除相关信息。基于上述行为而被飞天冻结、终止、删除等其他限制措施的，飞天无需向您退还任何费用，未使用的服务费用作为违约金归归飞天所有，您账号内的损失由您自行承担，造成飞天损失的（包括但不限于律师费、诉讼费等），您也应予赔偿。</p>

<p>7.如用户的违约行为使飞天遭受损失，包括飞天自身的直接经济损失、商誉损失及律师费、诉讼费等间接经济损失，用户应当赔偿飞天及上述全部损失。</p>

<p>8.你的违约行为导致任何第三方损害的，您应当独立独立承担责任。如因任何原因导致飞天先行向第三方赔偿，你应在收到飞天的通知后立即将赔偿金支付给飞天。</p>

<p>五、第三方软件或服务</p>

<p>本软件可能使用或包含了由第三方提供的软件或服务（以下称“此类服务”），此类服务是响应您需求，为了向您提供更多便利而设置，韵帆公司不提供内容制作。您使用此类服务时，应另行与该第三方达成服务协议，支付相应费用并承担可能的风险。您如果继续使用本软件或服务，则表示您默认以下几点：</p>

<p>1.飞天无权在本协议中授予您使用第三方软件或服务的任何权利，也无权对此类服务提供任何形式的保证以及相应的客户服务支持，如果您需要咨询相关信息，可直接与该第三方联系。如果您因使用此类服务引发任何纠纷或造成任务损失，可直接与该第三方协商解决，飞天不承担任何责任和赔偿。</p>

<p>2.您现使用的本软件服务及本软件当前包含的服务，飞天在此不保证您可永久使用本服务或本软件包含的服务永久不变，不保证将来不会使用除现有以外的其他同类或不同类型的软件或服务，一旦本软件中更改/增加服务内容或升级为包含前述服务内容的软件，其同样适用本协议规定。</p>

<p>3.如果第三方确认您违反了您与其之间关于使用此类服务的协议约定，并停止对您提供此类服务，同时要求飞天协助处理的，您理解并认可飞天将采取技术手段中止或终止您对该第三方软件的继续使用。</p>

<p>六、隐私权保护</p>

<p>您同意个人隐私信息是指那些能够对您进行个人辨识或涉及个人通信的信息，包括下列信息：您真实姓名，身份证号，手机号码，IP地址。本软件仅提供云手机服务，本软件不会主动采集您在云手机内使用第三方软件、游戏的账号、个人信息，但是由于您所使用的其他第三方软件、游戏等会自动在云手机硬件存储保存账号密码以及个人隐私信息，我们在您的云手机服务到期以后，系统将自动对您使用过的云手机进行信息清理，充分保障您的个人数据和隐私信息不会被用作其他用途。</p>

<p>尊重您个人隐私信息的私有性是韵帆公司的一贯制度，韵帆公司将会采取合理的措施保护您的个人隐私信息，除法律或有法律赋予权限的政府部门要求或您同意等原因外，韵帆公司未经您同意不向除合作单位以外的第三方公开、 透露您个人隐私信息。 但是，您在注册时选择同意，或您与飞天及其他合作单位之间就您个人隐私信息公开或使用另有约定的除外，同时您应自行承担因此可能产生的任何风险，飞天对此不予负责。</p>

<p>七、协议终止和违约责任</p>

<p>您应当在软件授权范围内使用本软件、尊重本软件及其所包含的知识产权、使用规范等相关内容；按本协议约定履行义务是您使用本软件的前提。</p>

<p>您如果违反本软件及其他相关使用要求、条款、协议、通告等内容，而被终止提供服务，以及您在使用过程中因非飞天软件本身功能障碍问题而终止使用飞天软件服务的，飞天无需向您退还任何费用，未使用的服务费用作为违约金归归飞天所有，您账号内的损失由您自行承担，造成飞天损失的（包括但不限于律师费、诉讼费等），您也应予赔偿。</p>

<p>您如果出于维护平台秩序及保护消费者权益的目的，向飞天作出任何形式的承诺，在飞天确认您违反了该承诺的前提下，飞天可按您的承诺或协议约定的方式对您的使用本软件的权益采取限制措施，包括中止或终止本协议。</p>

<p>如果您违反本协议或与飞天签订的其他协议约定，飞天可对您在其中获得的权益采取限制措施，包括中止、终止对您提供部分或全部服务，且在飞天官方平台依法公示您的违约情况。</p>

<p>本软件由您在各类下载平台自主下载获得，您需遵守相应下载平台对您使用本软件的相关约定，如果上述第三方平台确认您违反该约定需要飞天协助处理的情况，飞天可能会因第三方要求终止您使用本软件或服务。</p>

<p>在本协议终止时，您应停止对本软件的使用行为, 并删除本软件的全部副本。</p>

<p>如您违反本协议规定的条款，给飞天及或其他用户造成损失，您必须承担全部的赔偿责任。如我们承担了上述责任，则您同意赔偿我们的相关支出和损失。</p>

<p>责任免除，您同意，在法律允许的范围内，下属情况不属于飞天云手机违约，飞天云手机不承担任何责任：</p>

<p>1.您由于下属原因无法正常使用产品服务的情况：</p>

<p>（1）您遭到黑客攻击，导致数据丢失，服务中断；</p>

<p>（2）您由于第三方软件自身的系统、网络配置变更导致数据丢失，服务中断；</p>

<p>（3）您由于第三方软件自身的程序bug导致服务不可正常使用；</p>

<p>（4）其他由于非飞天原因导致的服务异常情况。</p>

<p>2.飞天云手机在进行服务器配置、维护时，需要短时间中断服务，且已提前通知您的；</p>

<p>3.非飞天云手机原因导致的Internet上的通路堵塞，造成您网络访问速度下降等服务异常或中断，包括但不限于：</p>

<p>（1）地震、爆炸、洪水、火灾、暴风雨、台风等天灾；</p>

<p>（2）战争、类似战争行为、敌对行为、武装冲突、恐怖活动、谋反、政变等情况；</p>

<p>（3）罢工、暴动、民众骚乱或恶意行为；</p>

<p>（4）电力或动力故障、公用设施或其他电信故障；</p>

<p>（5）政府行为、政策变化、封锁、禁运、电信部门技术调整、政府管制等。</p>

<p>4.其他免责情形</p>

<p>八、法律及争议解决</p>

<p>本协议的各项条款均适用中华人民共和国法律的相关规定。如无相关法律规定，则可参照行业惯例。</p>

<p>飞天可能根据业务调整而变更向您提供的软件或服务的主体，变更后，飞天与您共同履行本协议，以上变更不会影响您在本协议下的权益。发生争议时，您可根据具体使用的服务及对其权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</p>

<p>因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；如若协商不成，任何一方均可将有关争议提交至软件服务提供方所在人民法院解决。</p>

<p>九、信息变更</p>

<p>您如变更账户信息、通讯地址、电话等相关重要信息，须及时通知韵帆公司。因您未及时通知而导致自身受到的一切损失，由您自行承担责任。</p>

<p>十、赔偿</p>

<p>1、您因违反本协议或本协议项下的其他文件，或违反了任何法律、法规的规定，给韵帆公司造成损失的，应予赔偿，赔偿范围包括但不限于韵帆公司的直接损失、间接损失以及维权费用（律师费、诉讼费、保全费、鉴定费、诉讼费）等。</p>

<p>2、您因违反前款规定而侵害了第三方的权利，导致韵帆公司遭受任何第三方提起的索赔、诉讼或行政责任，您承诺无条件承担相应责任并使本网站免责。若因此给飞天造成损失的，应予赔偿，赔偿范围包括但不限于本网站的维权费用（律师费、诉讼费、保全费、鉴定费、诉讼费）、名誉损失以及韵帆公司向第三方支付的补偿金等。</p>

<p>3、本软件的云手机依托于硬件，受限于技术条件以及硬件损耗，请您理解：我们不会保存您在云手机内使用的第三方软件、游戏的账号数据和个人隐私信息。当硬件出现不可挽回的损伤的情况下，我们将会主动进行硬件维护和硬件升级，为了更加有效保护您的个人信息数据安全，此时您的第三方软件、游戏的账号和个人信息将会被清理，请您妥善保管好相关信息，我们不对数据的清理承担任何因储存或丢失而产生的法律责任。</p>

<p>4、您在购买服务后即认同飞天云手机按现状提供服务，若您中途主动取消或终止服务的，将不会获得飞天云手机服务费用的退还。您理解，飞天云手机无法保证所提供的产品服务毫无瑕疵，并同意即使飞天云手机提供的服务存在卡顿、闪退等瑕疵，也不被视为违约，您同意和飞天云手机一同合作解决服务瑕疵问题。</p>

<p>十一、其他条款</p>

<p>如果本协议中的任何条款无论因何种原因完全或部分无效，或不具备可执行力，均不影响其它条款的效力。</p>

<p>请您在使用本软件时，仔细阅读并遵守本许可协议和免责声明。</p>

<p>韵帆公司在法律允许的最大范围内对本协议拥有解释权与修改权。</p>

<p>韵帆数字(厦门)科技有限公司</p>

<p>2024年1月</p>

  </div>
</template>

<script>
export default {

}
</script>

<style>
    .agreement{
        margin: 100px 10px 0 10px;
        font-size: 20px;
        line-height: 30px;
    }
    .agreement h2{
        text-align: center;
    }
    .small{
        font-size: 12px;
    }
    a{
        text-decoration: none;
        color: rgb(73, 60, 119);
    }
</style>