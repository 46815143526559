var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-a"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"v1"},[_vm._v("飞天云 内置各类游戏工具的云手机")]),_vm._m(1),_c('div',{staticClass:"v3"},[_c('div',{staticClass:"v3-left",on:{"click":function($event){return _vm.download()}}},[_vm._m(2)]),_c('div',{staticClass:"v3-left",on:{"click":function($event){return _vm.ios()}}},[_vm._m(3)]),_c('div',{staticClass:"v3-left",on:{"click":function($event){return _vm.pc()}}},[_vm._m(4)]),_vm._m(5),_c('div',{staticClass:"v3-right"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.backup()}}},[_vm._v("网盘下载")])])]),_c('div',{staticClass:"v4"},[_c('span',{staticClass:"v4-1"},[_vm._v("版本号："+_vm._s(_vm.version)+" | ")]),_c('span',{staticClass:"v4-2"},[_vm._v(_vm._s(_vm.dateTime)+"  ")])]),_c('br'),_c('div',{staticClass:"v4"},[_c('span',{staticClass:"v4-1"},[_c('a',{staticClass:"agreement-a",attrs:{"href":"#"},on:{"click":_vm.toUser}},[_vm._v("《用户协议》")]),_vm._v(" | ")]),_c('span',{staticClass:"v4-2"},[_c('a',{staticClass:"agreement-a",attrs:{"href":"#"},on:{"click":_vm.toPrivacy}},[_vm._v("《隐私政策》")]),_vm._v("  ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{staticStyle:{"height":"68vh","width":"auto"},attrs:{"src":require("@/assets/img/i_40.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v2"},[_c('span',[_vm._v("飞天云手机，安卓10系统，月卡28元，免费试用3小时，南北方网络任意选，拒绝延迟。")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v3-left-connent"},[_c('img',{attrs:{"src":require("@/assets/img/i_31.png")}}),_c('span',[_vm._v("安卓端下载")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v3-left-connent"},[_c('img',{attrs:{"src":require("@/assets/img/i_ios.png")}}),_c('span',[_vm._v("IOS 网页端")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v3-left-connent"},[_c('img',{attrs:{"src":require("@/assets/img/i_31.png")}}),_c('span',[_vm._v("电脑端 试用")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v3-right ftyun-down-img"},[_c('img',{attrs:{"src":require("@/assets/img/ftyundown.png")}})])
}]

export { render, staticRenderFns }