<template>
  <div class="agreement">
    
    <p class=MsoNormal align=center style='text-align:center'><span
    style='font-size:16.0pt;font-family:宋体'>飞天云手机<span lang=EN-US>APP</span>所接入的第三方<span
    lang=EN-US>SDK</span>清单</span></p>

    <p class=MsoNormal><span lang=EN-US style='font-size:16.0pt;font-family:宋体'>&nbsp;&nbsp;&nbsp;
    </span><span style='font-size:16.0pt;font-family:宋体'>飞天所接入的部分第三方<span
    lang=EN-US>SDK</span>可能会申请调用您的设备权限、获取您的个人信息，以便您在不同的手机设备或第三方平台上正常使用相应功能<span
    lang=EN-US>/</span>服务，我们将这些具有个人信息收集功能的第三方<span lang=EN-US>SDK</span>的名称、个人信息使用目的及场景、申请调用的权限说明、个人信息收集类型及方式、隐私政策链接列明如下。</span></p>

    <p class=MsoNormal><span lang=EN-US style='font-size:16.0pt;font-family:宋体'>&nbsp;&nbsp;&nbsp;
    </span><span style='font-size:16.0pt;font-family:宋体'>请您了解：韵帆公司为飞天产品功能的实现与运行安全稳定而自研的<span
    lang=EN-US>SDK</span>不属于前述第三方<span lang=EN-US>SDK</span>，而其申请调用您的设备权限、获取您的个人信息的情况已经在飞天<span
    lang=EN-US>APP</span>隐私政策中结合产品功能进行说明，因而不在此列。</span></p>

    <p class=MsoNormal><span lang=EN-US style='font-size:16.0pt;font-family:宋体'>&nbsp;&nbsp;&nbsp;
    </span><span style='font-size:16.0pt;font-family:宋体'>韵帆公司会尽到审慎义务，努力保障您的信息安全，尽可能及时更新并向您说明飞天所接入第三方<span
    lang=EN-US>SDK</span>的最新情况。但也请您理解：飞天所接入的第三方<span lang=EN-US>SDK</span>提供方如未及时主动向飞天告知或未被飞天检测出存在调用您的设备权限、获取您的个人信息的情况，可能会被认为不涉及对您个人信息的收集使用，因而也不在此列明。</span></p>

    <p class=MsoNormal><span lang=EN-US>&nbsp;</span></p>

    <table class=MsoNormalTable border=1 cellpadding=0 style='border-top:solid #999999 1.0pt;
    border-left:solid #999999 1.0pt;border-bottom:none;border-right:none'>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:14.5pt;font-family:宋体;color:black'>功能类型</span></b></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>SDK</span></b><b><span style='font-size:14.5pt;font-family:宋体;
      color:black'>名称</span></b></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:14.5pt;font-family:宋体;color:black'>第三方公司名称</span></b></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:14.5pt;font-family:宋体;color:black'>使用目的</span></b></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:14.5pt;font-family:宋体;color:black'>申请调用的权限</span></b></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:14.5pt;font-family:宋体;color:black'>收集个人信息类型</span></b></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:14.5pt;font-family:宋体;color:black'>官网链接</span></b></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center'><b><span
      style='font-size:14.5pt;font-family:宋体;color:black'>隐私政策链接</span></b></p>
      </td>
    </tr>
    <tr style='height:87.7pt'>
      <td rowspan=11 style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'></td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>友盟组件化基础库</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>北京锐讯灵通科技有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>使用友盟其他</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>SDK</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>必须包含的基础组件</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；粗略获取位置；获取精确位置；读取外置存储卡；读取手机状态和身份；写入外部存储卡；检索正在运行的应用</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息；</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址；位置信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://developer.umeng.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt;height:87.7pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.umeng.com/page/policy</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备标识生成库</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>支付宝（中国）网络技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户生成唯一的标识</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取外置储存卡；读取收集状态和身份；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://opendocs.alipay.com/open/204/105296</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://opendocs.alipay.com/rules</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Fresco SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Facebook, Inc.</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>图像显示</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取联系人；读取外置存储卡；修改通讯录；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://frescolib.org/docs</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://docs.github.com/en/github/site-policy/github-privacy-statement</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>AndroidQuery SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'></td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>用于在</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Android</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>中执行异步任务和操作</span><span lang=EN-US style='font-size:14.5pt;
      font-family:"Helvetica",sans-serif;color:black'>UI</span><span
      style='font-size:14.5pt;font-family:宋体;color:black'>元素</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>获取粗略位置；获取精确位置；查找设备上的账号；读取外置存储卡；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://code.google.com/archive/p/android-query</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.gc.com.cn/termofservice/ysxy.htm</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>ShareContent SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>上海掌之淘信息技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户实现第三方登录、分享、好友列表操作</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取外置存储卡；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://github.com/MobClub/ShareSDK-for-Android</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.mob.com/about/policy1</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>MobLink SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>上海掌之淘信息技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>提供</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Web</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>与</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'>APP</span><span style='font-size:14.5pt;
      font-family:宋体;color:black'>直接跳转的产品服务，实现网页间直接跳转升级为</span><span lang=EN-US
      style='font-size:14.5pt;font-family:"Helvetica",sans-serif;color:black'>APP</span><span
      style='font-size:14.5pt;font-family:宋体;color:black'>间直接跳转</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>提供</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Web</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>与</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'>APP</span><span style='font-size:14.5pt;
      font-family:宋体;color:black'>直接跳转的产品服务，实现网页间直接跳转升级为</span><span lang=EN-US
      style='font-size:14.5pt;font-family:"Helvetica",sans-serif;color:black'>APP</span><span
      style='font-size:14.5pt;font-family:宋体;color:black'>间直接跳转</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://mobpush.mob.com/download</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.mob.com/about/policy1</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>华为</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>HMS</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>核心功能</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>华为技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>集成华为</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>HMS</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>其他</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'>SDK</span><span style='font-size:14.5pt;
      font-family:宋体;color:black'>时必须集成核心</span><span lang=EN-US style='font-size:
      14.5pt;font-family:"Helvetica",sans-serif;color:black'>HMS</span><span
      style='font-size:14.5pt;font-family:宋体;color:black'>核心功能包</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取手机状态和身份</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://developer.huawei.com/</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://developer.huawei.com/consumer/cn/devservice/term</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>谷歌</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Gson SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>谷歌公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>用于将</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Java</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>对象转换为</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'>JSON</span><span style='font-size:14.5pt;
      font-family:宋体;color:black'>表示</span><span lang=EN-US style='font-size:14.5pt;
      font-family:"Helvetica",sans-serif;color:black'>,</span><span
      style='font-size:14.5pt;font-family:宋体;color:black'>和将</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>JSON</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>字符串转换成一个等效的</span><span lang=EN-US style='font-size:14.5pt;
      font-family:"Helvetica",sans-serif;color:black'>Java</span><span
      style='font-size:14.5pt;font-family:宋体;color:black'>对象</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://code.google.com/p/google-gson/</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://code.google.com/p/google-gson/</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>阿里</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>HttpDns</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>阿里云计算有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>实现对阿里云的产品和服务的访问</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://develop.aliyun.com/tools/sdk#/android</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://terms.aliyun.com/legal-agreement/terms</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>百度慧推</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>北京百度网讯科技有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>向终端用户发送</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>push</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>/</span><span style='font-size:14.5pt;font-family:宋体;color:black'>写入外部储存卡；获取粗略位置；设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>位置信息、设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://growing.baidu.com/spp.html</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://sofire.bdstatic.com/push/privacy.html</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>百度安全</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>北京百度网讯科技有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>保护最终用户使用开发者应用时的终端设备安全性</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；读取手机状态和身份</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息、粗略位置信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://cloud.baidu.com/product/afd.html</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://sofire.bdstatic.com/secsdk/privacy.html</span></p>
      </td>
    </tr>
    <tr>
      <td rowspan=6 style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>数据统计分析</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>友盟统计分析</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>北京锐讯灵通科技有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>移动应用统计分析平台</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>获取粗略位置；获取精确位置</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>位置信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://developer.umeng.com/</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.umeng.com/page/policy</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Bugly SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯科技（深圳）有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>排查崩溃问题</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>,</span><span style='font-size:14.5pt;font-family:宋体;color:black'>帮助</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>APP</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>提升稳定性</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://bugly.qq.com/v2/</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>百度</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Crab</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>平台</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>北京百度网讯科技有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>处理线上</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>APP</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>每日崩溃跟踪</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；读取外置存储卡；读取手机状态和身份；写入外部存储卡；检索正在运行的应用</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息；</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://crab.baidu.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://tongji.baidu.com/web/help/article?id=330&amp;type=0</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>UC</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>啄木鸟</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>广州市动景计算机科技有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>提供线上质量监控以及异常定位分析服务</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取外置储存卡；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open-uc.uc.cn/solution/zhuomuniao</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open-uc.uc.cn/agreement/secret</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>阿里百川组件</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>阿里云计算有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>提供接口可用性统计服务</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://developer.alibaba.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://developer.alibaba.com</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯移动分析</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯科技（深圳）有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>提供线上质量监控以及异常定位分析服务</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://wiki.open.qq.com/wiki/stat/report/td&gt;</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://wiki.open.qq.com/wiki/stat/report</span></p>
      </td>
    </tr>
    <tr>
      <td rowspan=7 style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>登陆分享类</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>QQ</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>登陆</span><span lang=EN-US style='font-size:14.5pt;font-family:
      "Helvetica",sans-serif;color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯科技（深圳）有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户登陆</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>QQ</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>账号</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取外置存储卡；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://wiki.connect.qq.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://ti.qq.com/agreement/qqface.html?appname=mqq_2019</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>移动授权登陆</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>中国移动通信集团有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户登陆中国移动账号</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；读取手机状态和身份</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息；</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://www.cmpassport.com/dev/developer/dochtml/showdocument.html</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://dev.10086.cn/docInside?contentId=10000009826805</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>微信分享及收藏</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯科技（深圳）有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助微信用户分享和收藏文字、图片、音乐、视频、网页等</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；读取手机状态和身份；读取外置存储卡；写入外部存储卡；检索正在运行的应用</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息；</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open.weixin.qq.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>微信开放平台</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯科技（深圳）有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户使用微信分享、登录、收藏、支付等功能</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>读取外置存储卡；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open.weixin.qq.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&amp;t=weixin_agreement&amp;s=privacy</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>新浪微博开放平台</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>北京微梦创科网络技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>为用户提供了简单易用的微博授权登陆方式，并提供微博分享功能，可直接通过微博官方客户端分享微博</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://github.com/sinaweibosdk/weibo_android_sdk</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://github.com/sinaweibosdk/weibo_android_sdk</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>中国电信</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>中国电信集团公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户登陆中国电信帐号</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；检索正在运行的应用</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息；</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址；运营商信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://id.189.cn</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://e.189.cn/_agreements.html</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>百度</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>OAuth SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>北京百度网讯科技有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户登陆百度账号</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；读取手机状态和身份</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息、日志信息、用户名</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>/</span><span style='font-size:14.5pt;font-family:宋体;color:black'>手机号</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>/</span><span style='font-size:14.5pt;font-family:宋体;color:black'>邮箱</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://openauth.baidu.com/doc/</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://openauth.baidu.com/doc/agreement.html</span></p>
      </td>
    </tr>
    <tr>
      <td rowspan=8 style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>支付类</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>支付宝</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>蚂蚁金服（杭州）网络技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户使用支付宝服务</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；检索正在运行的应用</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.alipay.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://render.alipay.com/p/c/k2cx0tg8</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>支付宝安全</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'> SDK</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>蚂蚁金服（杭州）网络技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户安全使用支付宝服务</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>查看</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>WLAN</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>连接；读取外置存储卡；写入外部存储卡</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>设备信息；</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>Wi-Fi</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>地址</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open.alipay.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://render.alipay.com/p/c/k2cx0tg8</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>支付宝客户端支付安全</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>蚂蚁金服（杭州）网络技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>蚂蚁金服提供的安全插件，帮助用户安全使用支付宝服务</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open.alipay.com/</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open.alipay.com/</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>阿里聚安全安全组件</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>浙江淘宝网络有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>提供了安全签名、安全加密、安全存储、模拟器检测四大类接口</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open.taobao.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://open.taobao.com</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>MobPay</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>上海掌之淘信息技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>给用户提供快速支付功能</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://mobpush.mob.com/download</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.mob.com/about/policy1</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>MobPush</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>上海掌之淘信息技术有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>过滤色情、广告、涉政、暴恐等多类垃圾文字及敏感词，保证合规推送</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>http://mobpush.mob.com/download</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://www.mob.com/about/policy1</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯地图定位</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯科技（深圳）有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>帮助用户实现经纬度坐标偏转与当前位置的</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>POI</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>名称、地址或者行政区划的查询，节省流量与电量</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://lbs.qq.com/geo/index.html</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://lbs.qq.com/geo/index.html</span></p>
      </td>
    </tr>
    <tr>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯浏览服务</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>腾讯科技（深圳）有限公司</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>解决移动端</span><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>webview</span><span style='font-size:14.5pt;font-family:宋体;
      color:black'>使用过程中出现的一切问题，优化用户的浏览体验</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集个人敏感信息</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      style='font-size:14.5pt;font-family:宋体;color:black'>未收集</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://x5.tencent.com</span></p>
      </td>
      <td style='border-top:none;border-left:none;border-bottom:solid #999999 1.0pt;
      border-right:solid #999999 1.0pt;padding:.75pt .75pt .75pt .75pt'>
      <p class=MsoNormal align=center style='text-align:center;word-break:break-all'><span
      lang=EN-US style='font-size:14.5pt;font-family:"Helvetica",sans-serif;
      color:black'>https://x5.tencent.com</span></p>
      </td>
    </tr>
    </table>

    <p class=MsoNormal><span lang=EN-US>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style='font-size:16.0pt;font-family:宋体'>我们仅会出于合法、正当、必要且飞天云手机隐私政策中所明确的特定的目的与第三方分享您的个人信息，并且只会共享提供服务所必要的个人信息。对我们与之共享个人信息的第三方主体，我们会对其数据安全环境进行调查，与其签署严格的数据保护协议或者保密协定，要求他们依法采取保密和安全措施来处理个人信息。</span></p>


  </div>
</template>

<script>
export default {

}
</script>

<style>
    .agreement{
        margin: 100px 10px 0 10px;
        font-size: 20px;
        line-height: 30px;
    }
    .agreement h2{
        text-align: center;
    }
    table {
      border-collapse: collapse;
    }

    table, th, td {
      border: 1px solid black;
    }
</style>