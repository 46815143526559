<template>
  <div class="ipsw-list">
    <el-table  :data='this.$store.state.rowData' style="width: 100%">
      <el-table-column prop="name" label="设备名称" width="180" align="center"/>
      <el-table-column prop="type" label="产品类型" width="180" align="center"/>
      <el-table-column prop="version" label="固件版本" width="180" align="center"/> 
      <el-table-column prop="size" label="固件大小" width="180" align="center"/> 

      <el-table-column label="可否越狱" width="180" align="center">
        <template slot-scope="scope">
            <div class="yuan" :class="{action : scope.row.root}"></div>
        </template>
      </el-table-column>

      <el-table-column  label="可否刷机" width="180" align="center">
         <template slot-scope="scope">
            <div class="yuan" :class="{action : scope.row.signed}"></div>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button size="mini"  @click="download(scope.row.fileUrl)">下载</el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
export default {
  name: "IpswList",
  data() {
    return {
      
    }
  },
  methods: {
    download(url){
      window.open(url);
    },
    test(d){
      console.log(d);
    }
  },
};
</script>

<style scoped>
.ipsw-list {
  padding: 50px 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yuan{
  width: 12px;
  height: 12px;
  border-radius:10px ;
  background-color: #d8d8d8;
  margin: 0 auto;
}

.yuan.action{
  background-color: #247dff;
}
</style>