<template>
  <el-carousel :interval="5000" arrow="never">
    <el-carousel-item class="el-carousel-item"><page-a/></el-carousel-item>
    <!-- <el-carousel-item class="el-carousel-item"><page-b/></el-carousel-item> -->
  </el-carousel>
</template>

<script>
  import pageA from './item/pageA.vue';
  // import pageB from './item/pageB.vue';

  export default {
    name : "Swpier",
    components : {
      pageA,
      // pageB
    }
  }
</script>

<style scoped>
 .el-carousel{
    height: 700px;
    min-width: 90vh;
    width: 99vw;
    background: linear-gradient(180deg,#80acee,#e2768c)!important;
  }

  .el-carousel__item{
     height: 700px;
     display: flex;
     justify-content: center;
     align-items: center;
  }

  
</style>